<template>
  <div ref="classAndSubject" class="card container">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 3,
      }"
    >
      <div class="section-title">班级&学科</div>
    </exportBtn>
    <div class="operation mt30">
      <div class="selects">
        <div class="selects-item">
          <div class="label">选择班级：</div>
          <check-component
            :source="classNumsList"
            :placeholder="'选择班级'"
            @change="getCheckClassNum"
          />
        </div>
        <div class="selects-item">
          <div class="label">科目：</div>
          <check-component :source="subjectList" @change="getCheckSubject" />
        </div>
        <div v-if="category !== 1 && category !== 5" class="selects-item">
          <div class="label">选择指标：</div>
          <el-select v-model="type" style="width: 174px" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="category === 5" class="selects-item">
          <div class="label">选择指标：</div>
          <el-select v-model="type" style="width: 174px" placeholder="请选择">
            <el-option
              v-for="item in otherOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-radio-group v-model="category" size="small" @change="radioChange">
        <el-radio-button :label="1">平均分</el-radio-button>
        <el-radio-button v-if="onlines.length" :label="2"
          >上线情况</el-radio-button
        >
        <el-radio-button v-if="ratios.length" :label="3">比率</el-radio-button>
        <el-radio-button v-if="topSections.length" :label="4"
          >前N名</el-radio-button
        >
        <el-radio-button :label="5">其他指标</el-radio-button>
      </el-radio-group>
    </div>

    <div class="tableList">
      <el-table
        ref="elTable"
        :data="tableData"
        style="width: 100%"
        :cell-style="cellStyle"
      >
        <el-table-column
          prop="classNum"
          label="班级"
          :width="subjectcheckedList.length > 3 ? '80' : 'auto'"
          fixed
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableSubject"
          :key="'key' + index"
          :label="getLabel(item.subjectId)"
          align="center"
        >
          <el-table-column
            :label="getDynamicLabel(type)"
            show-overflow-tooltip
            :width="tableSubject.length > 4 ? '112' : 'auto'"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="category === 1">{{
                row.subjects[index].averageScore || "-"
              }}</span>
              <span v-else-if="category === 5">{{
                row.subjects[index][type] || "-"
              }}</span>
              <span v-else>
                {{ getRowValue(row, index) || "-" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="teacherName"
            :width="tableSubject.length > 4 ? '112' : 'auto'"
            show-overflow-tooltip
            :label="item.subjectId == 0 ? '班主任' : '任课老师'"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.subjects[index].teacherName">{{
                row.subjects[index].teacherName
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showColumn(index)"
            prop="schoolRank"
            label="校内排名"
            show-overflow-tooltip
            align="center"
            :width="tableSubject.length > 4 ? '112' : 'auto'"
          >
            <template slot-scope="{ row }">
              <div v-if="category === 1">
                {{ row.subjects[index].averageScoreRank || "-" }}
              </div>
              <div v-else-if="category === 5">
                {{ row.subjects[index][`${type}Rank`] || "-" }}
              </div>
              <div v-else>
                {{ getRowRank(row, index) || "-" }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div v-if="old.length > 10" class="btns">
        <el-button type="text" @click="isExtendChange">{{
          isExtend ? "收起" : "查看更多"
        }}</el-button>
      </div>
    </div>
    <div class="model-desc indent">
      此图按各班级总分平均分降序排列，蓝色代表班级指标值相对较大，红色代表班级指标值相对较小，横向查看同一班级不同学科情况，纵向比较同一学科不同班级情况，如出现色块跳跃则该班级该科值得关注。
    </div>
  </div>
</template>

<script>
import CheckComponent from "./checkComponent.vue";
import {
  getschoolreportclassandcubject,
  getstatInfo,
  exportSchoolReport,
} from "@/core/api/academic/schoolReportDetail";
import { UnionGetschoolreportclassandcubject } from "@/core/api/academic/unionReport";
import { getstatclassoption } from "@/core/api/academic/common";
import exportBtn from "@/views/academic/components/exportBtn";
import axios from "axios";
export default {
  name: "ClassAndSubject",
  components: {
    CheckComponent,
    exportBtn,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statId: this.$route.query.id,
      requestFunc: exportSchoolReport,
      activeName: "1",
      tableData: [],
      tableLabelList: [],
      category: 1,
      type: "平均分",
      options: [],
      top: [],
      rates: [],
      onlines: [],
      classNumsList: [],
      subjectList: [],
      subjectcheckedList: [],
      tableSubject: [],
      old: [],
      field: "",
      rankAverage: 0,
      otherOptions: [
        {
          name: "最高分",
          value: "highestScore",
        },
        {
          name: "最低分",
          value: "lowestScore",
        },
        {
          name: "全距",
          value: "rangeScore",
        },
        {
          name: "中位数",
          value: "median",
        },
        {
          name: "离均差（校）",
          value: "deviationFromMean",
        },
        {
          name: "超均率（校）",
          value: "overAverageRate",
        },
        {
          name: "标准差",
          value: "standardDeviation",
        },
        {
          name: "差异系数",
          value: "coefficientOfVariation",
        },
      ],
      isExtend: false,
      ratios: [],
      topSections: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.elTable.doLayout();
    });
    this.getConditionData();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.elTable.doLayout();
    });
  },
  methods: {
    isExtendChange() {
      this.isExtend = !this.isExtend;
      this.tableData = this.isExtend ? this.old : this.old.slice(0, 10);
    },
    getDynamicLabel(type) {
      if (this.category === 5) {
        const data = this.otherOptions.find((item) => item.value === type);
        if (data) {
          return data.name;
        } else {
          return type;
        }
      } else {
        return type;
      }
    },
    cellStyle({ row, columnIndex }) {
      if (columnIndex === 0) {
        return "background:#fff";
      } else {
        if (columnIndex % 3 >= 0) {
          let colrank;
          if (this.category === 1) {
            colrank =
              row.subjects[Math.ceil(columnIndex / 3) - 1].averageScoreRank ||
              0;
          } else if (this.category === 5) {
            colrank =
              row.subjects[Math.ceil(columnIndex / 3) - 1][`${this.type}Rank`];
          } else {
            if (row.subjects[Math.ceil(columnIndex / 3) - 1][this.field]) {
              let data = row.subjects[Math.ceil(columnIndex / 3) - 1][
                this.field
              ].find((item) => item.name === this.type);
              colrank = data.rank;
            }
            if (!colrank) {
              return "background:#fff";
            }
          }
          if (colrank < 0) {
            return `background:rgba(255,255,255);color:#333;`;
          } else {
            if (colrank <= this.rankAverage) {
              return `background:rgba(136,186,234,${
                1 - (0.8 / this.rankAverage) * colrank
              });color:#333;`;
            } else {
              const length = this.old.length - colrank;
              if (length) {
                return `background:rgba(231,67,67,${
                  (0.5 / length) * (colrank - this.rankAverage)
                });color:#333;`;
              } else {
                return `background:rgba(231,67,67,${
                  0.9 * (colrank - this.rankAverage)
                });color:#333;`;
              }
            }
          }
        }
      }
    },
    radioChange() {
      switch (this.category) {
        case 1:
          this.type = "平均分";
          break;
        case 2:
          this.field = "onlines";
          if (this.old.length) {
            this.type =
              (this.old[0].subjects[0].onlines.length &&
                this.old[0].subjects[0].onlines[0].name) ||
              "";
            this.options = this.old[0].subjects[0].onlines;
          }
          break;
        case 3:
          this.field = "ratios";
          if (this.old.length) {
            this.type =
              (this.old[0].subjects[0].ratios.length &&
                this.old[0].subjects[0].ratios[0].name) ||
              "";
            this.options = this.old[0].subjects[0].ratios;
          }
          break;
        case 4:
          this.field = "topSections";
          if (this.old.length) {
            this.type =
              (this.old[0].subjects[0].topSections.length &&
                this.old[0].subjects[0].topSections[0].name) ||
              "";
            this.options = this.old[0].subjects[0].topSections;
          }
          break;
        case 5:
          this.type = "median";
          break;
      }
    },
    getRowValue(row, index) {
      if (this.type && this.field) {
        const data = row.subjects[index][this.field].find(
          (item) => item.name === this.type
        );
        return `${data.proportion}`;
      }
    },
    getRowRank(row, index) {
      if (this.type && this.field) {
        const data = row.subjects[index][this.field].find(
          (item) => item.name === this.type
        );
        return `${data.rank}`;
      }
    },
    getCheckSubject(val) {
      this.subjectcheckedList = [...val];
      this.getschoolreportclassandcubjectData();
    },
    getCheckClassNum(val) {
      let classList = val.map((item) => item.name);
      this.classNumCheckList = [...classList];
      let arr = this.old.filter((item) => {
        if (classList.includes(item.classNum)) {
          return item;
        }
      });
      this.tableData = this.sortArr(arr);
    },
    async getConditionData() {
      // 通过axios.all 并发 请求统计科目 和 班级列表
      const res = await axios.all([
        this.getstatInfoData(),
        this.getstatclassoptionData(),
      ]);
      const [info, criteria] = res;
      this.subjectcheckedList = [
        { name: "总分", subjectId: 0 },
        ...info.data.data.statSubjects,
      ];
      this.subjectList = [
        { name: "总分", subjectId: 0 },
        ...info.data.data.statSubjects,
      ];
      this.classNumsList = criteria.data.data;
      this.classNumCheckList = criteria.data.data.map((item) => item.name);
      this.getschoolreportclassandcubjectData();
    },
    getstatInfoData() {
      // 获取统计科目
      return getstatInfo({ id: this.statId });
    },
    sortArr(arr) {
      let classNumList = [...new Set(arr.map((item) => item.classNum))];
      const data = classNumList.map((item) => {
        return {
          ...arr.find((it) => it.classNum === item),
        };
      });
      return data;
    },
    getLabel(id) {
      if (!Number(id)) {
        return "总分";
      } else {
        const data = this.subjectList.find((item) => {
          if (item.subjectId == id) {
            return item;
          } else {
            return null;
          }
        });
        return data ? data.name : "-";
      }
    },
    getstatclassoptionData() {
      //校级报告-班级竞争力-查询条件(科目和班级)
      return getstatclassoption({
        id: this.statId,
        requestPage: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
    },
    showColumn(index) {
      if (this.tableData.length) {
        if (this.category == 1) {
          const result = this.tableData.every(
            (item) => item.subjects[index].averageScoreRank == -1
          );
          return !result;
        } else if (this.category == 5) {
          const result = this.tableData.every(
            (item) => item.subjects[index][`${this.type}Rank`] == -1
          );
          return !result;
        } else {
          const result = this.tableData.every(
            (item) => this.getRowRank(item, index) == -1
          );
          return !result;
        }
      }
    },
    async getschoolreportclassandcubjectData() {
      try {
        let res;
        if (this.$route.query.examType == 1) {
          res = await UnionGetschoolreportclassandcubject({
            id: this.statId,
            classNums: this.classNumCheckList.toString(),
            viewSchoolId: this.$route.query.schoolId,
            subjectIds: this.subjectcheckedList
              .map((item) => item.subjectId)
              .toString(),
          });
        } else {
          res = await getschoolreportclassandcubject({
            id: this.statId,
            classNums: this.classNumCheckList.toString(),
            subjectIds: this.subjectcheckedList
              .map((item) => item.subjectId)
              .toString(),
          });
        }
        if (res.data.data.length) {
          if (res.data.data[0].subjects.length) {
            this.onlines = res.data.data[0].subjects[0].onlines;
            this.ratios = res.data.data[0].subjects[0].ratios;
            this.topSections = res.data.data[0].subjects[0].topSections;
          }
        }
        this.tableData = res.data.data.slice(0, 10);
        this.isExtend = false;
        this.old = res.data.data;
        this.tableSubject = this.tableData.length && this.tableData[0].subjects;
        res.data.data.forEach((item) => {
          const totalArr = item.subjects.filter((it) => it.subjectId == 0);
          const obj = totalArr.find(
            (it) => it.averageScore >= this.topData.averageScore
          );
          if (obj && obj.averageScoreRank > this.rankAverage) {
            this.rankAverage = obj.averageScoreRank;
          }
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .tag {
    font-size: 14px;
    border: 1px dashed #2474ed;
    color: #737677;
    background-color: #f2f8fe;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 24px;
    line-height: 1.5;

    div {
      margin-bottom: 12px;
    }

    & :last-child {
      margin: 0;
    }
  }

  .section-sub-title {
    font-size: 16px;
    color: #333333;
    font-weight: 550;
    margin-bottom: 24px;
  }

  .tableList {
    margin-bottom: 32px;
    width: 100%;

    ::v-deep .hover-row {
      .el-table__cell {
        background-color: #fff5d7;
      }
    }
  }

  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;

    .selects {
      display: flex;
      align-items: center;

      &-item {
        display: flex;
        align-items: center;
        margin-right: 25px;

        .label {
          font-size: 14px;
          color: #080a09;
          margin-right: 8px;
        }
      }
    }
  }
}

// .el-table {
//   &::v-deep {
//     .el-table__body-wrapper::-webkit-scrollbar {
//       /*width: 0;宽度为0隐藏*/
//       height: 10px;
//     }
//     .el-table__body-wrapper::-webkit-scrollbar-thumb {
//       border-radius: 8px;
//       height: 8px;
//       background: #a1a1a1; //滚动条颜色
//     }
//     .el-table__body-wrapper::-webkit-scrollbar-track {
//       box-shadow: inset 0 0 5px rgba(241, 240, 245, 0.2);
//       background: #ececec; //滚动条背景色
//       height: 8px;
//     }
//   }
// }
// ::v-deep .el-table__fixed {
//   height: calc(100% - 10px) !important;
// }
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}

::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}

::v-deep .el-icon-arrow-left {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

::v-deep .el-icon-arrow-right {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

.filter {
  margin: 28px 0;
  display: flex;
  align-items: center;

  .title {
    color: #333333;
    font-size: 16px;
    margin-right: 28px;
    font-weight: 550;
  }

  .label {
    font-size: 14px;
    font-weight: 550;
    color: #080a09;
  }
}

::v-deep .el-select-dropdown {
  display: none !important;
  opacity: 0 !important;
}

::v-deep .el-popper {
  display: none !important;
  opacity: 0 !important;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;

  &:hover {
    color: #fff;
  }
}

::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}

.btns {
  text-align: center;
  margin-top: 10px;
}
</style>
