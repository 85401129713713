<template>
  <div ref="ClassCompetitive" v-loading="loading" class="card container">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 2,
      }"
    >
      <div class="section-title">班级竞争力</div>
    </exportBtn>
    <div class="model-desc mt30">
      <div class="indent">{{ textObj.overview1 }}</div>
      <div class="indent">{{ textObj.overview2 }}</div>
      <div class="indent">{{ textObj.overview3 }}</div>
      <div class="indent">{{ textObj.overview4 }}</div>
    </div>
    <el-tabs
      v-model="subjectId"
      style="margin: 20px 0 10px"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(item, index) in tabs"
        :key="index"
        :label="item.name"
        :name="item.id.toString()"
      ></el-tab-pane>
    </el-tabs>
    <div class="filter">
      <div class="section-sub-title">班级指标对比</div>
      <div class="rows">
        <div v-if="subjectId != 0 && $route.query.examType != 1" class="row">
          <div class="label">班级类型：</div>
          <el-select
            v-model="classType"
            placeholder="请选择"
            style="width: 95px"
            @change="classTypeChange"
          >
            <el-option
              v-for="item in classTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <div class="label">选择班级：</div>
          <check-component
            :placeholder="'选择班级'"
            :source="classNumsList"
            @change="handleChange"
          />
        </div>
      </div>
    </div>
    <div class="tableList">
      <el-table
        ref="elTable"
        :data="tableData"
        :header-cell-style-name="{ whiteSpace: 'nowrap' }"
        style="width: 100%"
        @sort-change="soltHandle"
      >
        <el-table-column
          prop="classNum"
          label="班级"
          fixed
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="teacherName"
          :label="subjectId == 0 ? '班主任' : '任课教师'"
          fixed
          width="120"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.classNum">{{
              ["整体高位班级", "整体", "本校"].includes(row.classNum)
                ? "-"
                : row.teacherName
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="statTotal"
          label="统计人数"
          fixed
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="averageScore"
          fixed
          label="平均分"
          sortable="custom"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in onlines"
          :key="index"
          :label="item.name"
          align="center"
        >
          <el-table-column
            v-if="examType == 1"
            :sort-by="`onlines,${index},ep`"
            prop="ep"
            label="有效分"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.onlines.length">{{ row.onlines[index].ep }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :sort-by="`onlines,${index},total`"
            prop="total"
            label="人数"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.onlines.length"
                >{{ row.onlines[index].total }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="proportion"
            :sort-by="`onlines,${index},proportion`"
            label="占比"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.onlines.length">{{
                row.onlines[index].proportion
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="examType == 1 && topData.isSchoolDisplayReportClassRank == 1"
            :sort-by="`onlines,${index},rank`"
            prop="rank"
            label="排名"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.onlines.length">{{
                ["整体", "本校"].includes(row.classNum)
                  ? "-"
                  : row.onlines[index].rank
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in ratios"
          :key="'ratios' + index"
          :label="item.name"
          align="center"
        >
          <el-table-column
            label="人数"
            prop="total"
            :sort-by="`ratios,${index},total`"
            sortable="custom"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.ratios[index].total
            }}</template>
          </el-table-column>
          <el-table-column
            label="占比"
            prop="proportion"
            :sort-by="`ratios,${index},proportion`"
            sortable="custom"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.ratios[index].proportion
            }}</template>
          </el-table-column>
          <el-table-column
            v-if="examType == 1 && topData.isSchoolDisplayReportClassRank == 1"
            label="排名 "
            prop="rank"
            :sort-by="`ratios,${index},rank`"
            sortable="custom"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">{{
              ["整体", "本校"].includes(row.classNum)
                ? "-"
                : row.ratios[index].rank
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in topSections"
          :key="'topSections' + index"
          :label="item.name"
          align="center"
        >
          <el-table-column
            label="人数"
            sortable="custom"
            width="100"
            prop="total"
            :sort-by="`topSections,${index},total`"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.topSections[index].total
            }}</template>
          </el-table-column>
          <el-table-column
            label="占比"
            prop="proportion"
            :sort-by="`topSections,${index},proportion`"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.topSections[index].proportion
            }}</template>
          </el-table-column>
          <el-table-column
            v-if="examType == 1 && topData.isSchoolDisplayReportClassRank == 1"
            label="排名"
            prop="rank"
            :sort-by="`topSections,${index},rank`"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">{{
              ["整体", "本校"].includes(row.classNum)
                ? "-"
                : row.topSections[index].rank
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="highestScore"
          label="最高分"
          width="100px"
          sortable="custom"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="lowestScore"
          label="最低分"
          width="100px"
          sortable="custom"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="rangeScore"
          label="全距"
          width="100px"
          sortable="custom"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="median"
          label="中位数"
          width="100px"
          sortable="custom"
          align="center"
        >
        </el-table-column>
        <template v-if="examType == 1">
          <el-table-column
            prop="deviationFromMeanUnion"
            label="离均差（整体）"
            width="150px"
            sortable="custom"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="overAverageRateUnion"
            label="超均率（整体）"
            width="150px"
            sortable="custom"
            align="center"
          >
          </el-table-column
        ></template>
        <el-table-column
          prop="deviationFromMean"
          label="离均差（校）"
          width="150px"
          sortable="custom"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="overAverageRate"
          label="超均率（校）"
          width="150px"
          sortable="custom"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="standardDeviation"
          label="标准差"
          width="150px"
          sortable="custom"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="coefficientOfVariation"
          label="差异系数"
          width="150px"
          sortable="custom"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="more">
      <el-button
        v-if="classData.length > 10"
        type="text"
        @click="extendChange"
        >{{ isExtend ? "收起" : "展开全部" }}</el-button
      >
    </div>
    <div class="chart-box">
      <div class="operation">
        <div class="selects">
          <div v-if="category !== 1 && category !== 5" class="selects-item">
            <div class="label">选择指标：</div>
            <el-select
              v-model="type"
              style="width: 174px"
              placeholder="请选择"
              @change="selectChange"
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="category === 5" class="selects-item">
            <div class="label">选择指标：</div>
            <el-select
              v-model="otherType"
              style="width: 174px"
              placeholder="请选择"
              @change="otherSelectChange"
            >
              <el-option
                v-for="item in otherOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-radio-group v-model="category" size="small" @change="radioChange">
          <el-radio-button :label="1">平均分</el-radio-button>
          <el-radio-button v-if="onlines.length" :label="2"
            >上线情况</el-radio-button
          >
          <el-radio-button v-if="topSections.length" :label="3"
            >比率</el-radio-button
          >
          <el-radio-button v-if="ratios.length" :label="4"
            >前N名</el-radio-button
          >
          <el-radio-button :label="5">其他指标</el-radio-button>
        </el-radio-group>
      </div>
      <div class="chart">
        <bar-chart :data="chartOptions" />
      </div>
    </div>
    <div class="section-sub-title">班级成绩分布</div>
    <div class="operation-brach">
      <div v-if="search.type === 0" class="filter">
        <div class="label">分数段区间设置：</div>
        <div class="filter-item">
          <div class="filter-item-label">上限</div>
          <el-input
            v-model.number="search.high"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.high = $event.target.value"
          ></el-input>
          <div class="unit">分</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">下限</div>
          <el-input
            v-model.number="search.low"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.low = $event.target.value"
          ></el-input>
          <div class="unit">分</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">间隔</div>
          <el-input
            v-model.number="search.interval"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.interval = $event.target.value"
          ></el-input>
          <div class="unit">分</div>
        </div>
        <el-button type="primary" plain @click="classscoredistributionData"
          >确定</el-button
        >
      </div>
      <div v-else class="filter">
        <div class="label">排名区间设置：</div>
        <div class="filter-item" style="margin-right: 5px">
          <el-input
            v-model.number="search.high"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.high = $event.target.value"
          ></el-input>
          <div class="unit">名</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">—</div>
          <el-input
            v-model.number="search.low"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.low = $event.target.value"
          ></el-input>
          <div class="unit">名</div>
        </div>
        <div class="filter-item">
          <div class="filter-item-label">间隔</div>
          <el-input
            v-model.number="search.interval"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="search.interval = $event.target.value"
          ></el-input>
          <div class="unit">名</div>
        </div>
        <el-button type="primary" plain @click="classscoredistributionData"
          >确定</el-button
        >
      </div>
      <el-radio-group v-model="search.type" size="small" @change="typeChange">
        <el-radio-button :label="0">分数段</el-radio-button>
        <el-radio-button v-if="examType != 1" :label="1">排名</el-radio-button>
      </el-radio-group>
    </div>

    <div v-if="heatData" class="heatData">
      <heat-bar
        :key="chartKey"
        :source="heatData"
        x-aixs-reverse="top"
        :tooltip="tooltip"
        :item-height="50"
      />
      <div class="model-desc indent">
        横坐标为班级，按照班级名称排序，纵坐标为分数（或排名）区间，色块数值为该班级该分数（或排名）区间内的人数，人数越多则颜色越深，如出现空白区域，则该班级该分数（或排名）区域内没有人数，如分数（或排名）中段区域连续出现多个空白，则代表该班级该分数（或排名）范围内学生断档。
      </div>
    </div>
    <no-data v-else></no-data>
  </div>
</template>

<script>
import heatBar from "../../charts/heat.vue";
import barChart from "./chart.vue";
import CheckComponent from "./checkComponent.vue";
import {
  getschoolreportclasscompetitive,
  classscoredistribution,
  exportSchoolReport,
} from "@/core/api/academic/schoolReportDetail";
import {
  getstatsubjectoption,
  getstatclassoption,
  getstatclassteachoption,
} from "@/core/api/academic/common";
import {
  UnionGetschoolreportclasscompetitive,
  unionClassscoredistribution,
} from "@/core/api/academic/unionReport";
import exportBtn from "@/views/academic/components/exportBtn";

import axios from "axios";
export default {
  name: "ClassCompetitive",
  components: {
    heatBar,
    barChart,
    CheckComponent,
    exportBtn,
  },
  props: {
    topData: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      subjectId: "0",
      requestFunc: exportSchoolReport,
      tableData: [],
      topSections: [],
      onlines: [],
      ratios: [],
      examType: this.$route.query.examType,
      search: {
        high: 1,
        low: 100,
        type: 0,
        interval: 10,
      },
      loading: false,
      category: 1,
      label: "",
      options: [
        {
          label: "最高分",
          value: "",
        },
        {
          label: "最低分",
        },
        {
          label: "全距",
        },
        {
          label: "中位数",
        },
        {
          label: "离均差（校）",
        },
        {
          label: "超均差（校）",
        },
        {
          label: "标准差",
        },
        {
          label: "异差系数",
        },
      ],
      type: "",
      classNumsList: [],
      chartData: [],
      classNums: "",
      statId: this.$route.query.id,
      tabs: [],
      chartOptions: {},
      classData: [],
      schoolData: [],
      categoryField: "",
      otherOptions: [
        {
          label: "最高分",
          value: "highestScore",
        },
        {
          label: "最低分",
          value: "lowestScore",
        },
        {
          label: "全距",
          value: "rangeScore",
        },
        {
          label: "中位数",
          value: "median",
        },
        {
          label: "离均差（校）",
          value: "deviationFromMean",
        },
        {
          label: "超均率（校）",
          value: "overAverageRate",
        },
        {
          label: "标准差",
          value: "standardDeviation",
        },
        {
          label: "差异系数",
          value: "coefficientOfVariation",
        },
      ],
      otherType: "",
      textObj: {},
      heatData: {},
      isExtend: false,
      tooltip: null,
      totalData: [],
      chartKey: Math.random(),
      classType: 1,
      classTypeOptions: [
        {
          label: "行政班",
          value: 1,
        },
        {
          label: "教学班",
          value: 2,
        },
      ],
      classNumData: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.elTable.doLayout();
    });
    this.getConditionData();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.elTable.doLayout();
    });
  },
  methods: {
    async classTypeChange() {
      if (this.classType == 1) {
        this.classNumsList = [...this.classNumData];
      } else {
        const res = await getstatclassteachoption({
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
        this.classNumsList = [...res.data.data];
        this.classNums = res.data.data.map((item) => item.name).toString();
      }
      this.getschoolreportclasscompetitiveData();
    },
    extendChange() {
      this.isExtend = !this.isExtend;
      let data = this.isExtend ? this.classData : this.classData.slice(0, 10);
      this.tableData = JSON.parse(JSON.stringify(data));
      this.tableData = [...this.tableData, this.schoolData];
    },
    setTableData() {
      if (
        this.$route.query.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        this.tableData = [...this.tableData, this.schoolData, this.totalData];
      } else {
        this.tableData = [...this.tableData, this.schoolData];
      }
    },
    soltHandle(column) {
      let fieldName = column.prop;
      let sortingType = column.order;
      let { sortBy } = column.column;
      if (!sortBy) {
        if (sortingType == "ascending") {
          // 正序
          this.classData.sort((a, b) => b[fieldName] - a[fieldName]);
          this.tableData = this.isExtend
            ? this.classData
            : this.classData.slice(0, 10);
          this.setTableData();
        } else if (sortingType == "descending") {
          this.classData.sort((a, b) => a[fieldName] - b[fieldName]);
          this.tableData = this.isExtend
            ? this.classData
            : this.classData.slice(0, 10);
          this.setTableData();
        }
      } else if (sortBy.indexOf(",") != -1) {
        let [arrName, index, arrFieldName] = sortBy.split(",");
        if (sortingType == "ascending") {
          this.classData.sort((a, b) => {
            return (
              b[arrName][Number(index)][arrFieldName] -
              a[arrName][Number(index)][arrFieldName]
            );
          });
          this.tableData = this.isExtend
            ? this.classData
            : this.classData.slice(0, 10);
          this.setTableData();
        } else if (sortingType == "descending") {
          this.classData.sort((a, b) => {
            return (
              a[arrName][Number(index)][arrFieldName] -
              b[arrName][Number(index)][arrFieldName]
            );
          });
          this.tableData = this.isExtend
            ? this.classData
            : this.classData.slice(0, 10);
          this.setTableData();
        } else {
          this.tableData = this.isExtend
            ? this.classData
            : this.classData.slice(0, 10);
          this.setTableData();
        }
      }
    },
    otherSelectChange() {
      let data = this.classData.map((item) => item[this.otherType]);
      let xAxis = this.classData.map((item) => item.classNum);
      let item = this.otherOptions.find(
        (item) => item.value === this.otherType
      );

      let series = [
        {
          name: item.label, // 不同条柱
          type: "bar",
          itemStyle: {
            normal: {
              color: function (params) {
                if (params.value > 0) {
                  return "#7AB5EF";
                } else {
                  return "#FD8585";
                }
              },
            },
          },
          data: data.map((it) => {
            return {
              value: it,
              label: {
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
                position: it >= 0 ? "top" : "bottom",
                formatter: ({ value }) => {
                  return Math.abs(value);
                },
              },
            };
          }),
          barMaxWidth: 40,
        },
        {
          name: "全校" + item.label,
          type: "line",
          label: {
            position: "top",
            show: false,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            formatter: ({ value }) => {
              return Math.abs(value);
            },
          },
          data: new Array(xAxis.length).fill(
            this.schoolData[this.otherType],
            0,
            xAxis.length
          ),
        },
      ];
      if (
        this.$route.query.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        series = [
          ...series,
          {
            name: "整体", // 不同条柱
            type: "line",
            smooth: true,
            label: {
              position: "top",
              show: false,
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
              formatter: ({ value }) => {
                return Math.abs(value);
              },
            },
            color: "#FF8857",
            data: this.classData.map(() => this.totalData[this.otherType]),
          },
        ];
      }
      if (
        this.otherType == "overAverageRate" ||
        this.otherType == "deviationFromMean"
      ) {
        series = [
          {
            name: item.label, // 不同条柱
            type: "bar",
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.value > 0) {
                    return "#7AB5EF";
                  } else {
                    return "#FD8585";
                  }
                },
              },
            },
            data: data.map((it) => {
              return {
                value: it,
                label: {
                  show: true,
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                  position: it >= 0 ? "top" : "bottom",
                  formatter: ({ value }) => {
                    return value;
                  },
                },
              };
            }),
            barMaxWidth: 40,
          },
        ];
      }
      this.chartOptions = {
        xAxis: [
          {
            type: "category",
            data: this.classData.map((item) => item.classNum),
            axisTick: {
              show: false,
            },
          },
        ],
        grid: {
          bottom: data.length > 25 ? "20%" : "5%",
        },
        dataZoom: [
          //滚动条
          {
            show: data.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: data.length - (data.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        series: series,
      };
    },
    selectChange() {
      const arr = this.classData.flatMap((item) => item[this.categoryField]);
      let xAxis = this.classData.map((item) => item.classNum);
      let school = this.schoolData[this.categoryField].find(
        (item) => item.name === this.type
      );
      let series = [
        {
          name: this.type, // 不同条柱
          type: "bar",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: arr
            .map((item) => {
              if (item.name === this.type) {
                return item.proportion || "0";
              }
            })
            .filter((item) => item),
          barMaxWidth: 40,
        },
        {
          name: `全校${this.type}`,
          type: "line",
          label: {
            position: "top",
            show: false,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
          },
          data: new Array(xAxis.length).fill(
            school.proportion || 0,
            0,
            xAxis.length
          ),
        },
      ];
      if (this.$route.query.examType == 1) {
        let totalData = this.totalData[this.categoryField].find(
          (item) => item.name === this.type
        );
        series = [
          ...series,
          {
            name: `整体${this.type}`,
            type: "line",
            label: {
              position: "top",
              show: false,
              color: "#FF8857",
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
            },
            data: new Array(xAxis.length).fill(
              totalData.proportion || 0,
              0,
              xAxis.length
            ),
          },
        ];
      }
      this.chartOptions = {
        xAxis: [
          {
            type: "category",
            data: this.classData.map((item) => item.classNum),
            axisTick: {
              show: false,
            },
          },
        ],
        grid: {
          bottom: arr.length > 25 ? "20%" : "5%",
        },
        dataZoom: [
          //滚动条
          {
            show: arr.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: arr.length - (arr.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        series: [...series],
      };
    },
    handleChange(val) {
      this.classNums = val.map((item) => item.name).toString();
      this.getschoolreportclasscompetitiveData();
    },
    radioChange() {
      switch (this.category) {
        case 1:
          this.averageScoreFilter();
          break;
        case 2:
          this.onlinesFilter();
          break;
        case 3:
          this.ratesFilter();
          break;
        case 4:
          this.topFilter();
          break;
        case 5:
          this.otherFilter();
          break;
      }
    },
    typeChange() {
      if (this.search.type == 0) {
        this.search.high = this.topData.segmentMaximum;
        this.search.low = this.topData.segmentMinimum;
        this.search.interval = this.topData.segmentGap;
      } else {
        this.search.high = this.topData.rankMaximum || null;
        this.search.low = this.topData.rankMinimum || null;
        this.search.interval = this.topData.rankGap || null;
      }
      this.classscoredistributionData();
    },
    otherFilter() {
      this.otherType = "median";
      this.otherSelectChange();
    },
    averageScoreFilter() {
      let xAxis = this.classData.map((item) => item.classNum);
      let series = [
        {
          name: "平均分", // 不同条柱
          type: "bar",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: this.classData.map((item) => item.averageScore),
          barMaxWidth: 40,
        },
        {
          name: "全校平均分",
          type: "line",
          label: {
            position: "top",
            show: false,
            textStyle: {
              color: "#FF5233",
            },
          },
          data: new Array(xAxis.length).fill(
            this.schoolData.averageScore,
            0,
            xAxis.length
          ),
        },
      ];
      if (
        this.$route.query.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        series = [
          ...series,
          {
            name: "整体",
            type: "line",
            label: {
              position: "top",
              show: false,
              textStyle: {
                color: "#FF8857",
              },
            },
            data: this.classData.map(() => this.totalData.averageScore),
          },
        ];
      }
      this.chartOptions = {
        xAxis: [
          {
            type: "category",
            data: this.classData.map((item) => item.classNum),
            axisTick: {
              show: false,
            },
          },
        ],
        grid: {
          bottom: this.classData.length > 25 ? "20%" : "5%",
        },
        dataZoom: [
          //滚动条
          {
            show: this.classData.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: this.classData.length - (this.classData.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        series: [...series],
      };
    },
    ratesFilter() {
      this.options = this.ratios;
      this.type = this.ratios[0].name;
      this.categoryField = "ratios";
      this.selectChange();
    },
    topFilter() {
      this.options = this.topSections;
      this.type = this.topSections[0].name;
      this.categoryField = "topSections";
      this.selectChange();
    },
    onlinesFilter() {
      this.options = this.onlines;
      this.type = this.onlines[0].name;
      this.categoryField = "onlines";
      this.selectChange();
    },
    async getConditionData() {
      // 通过axios.all 并发 请求统计科目 和 班级列表
      this.loading = true;
      const res = await axios.all([
        this.getstatInfoData(),
        this.getstatclassoptionData(),
      ]);
      const [info, criteria] = res;
      if (info.data.data.length) {
        this.subjectId = info.data.data[0].id.toString();
        this.search.high = info.data.data[0].segmentMaximum;
        this.search.low = info.data.data[0].segmentMinimum;
        this.search.interval = info.data.data[0].segmentGap;
      }
      this.tabs = info.data.data;
      this.classNumsList = criteria.data.data;
      this.classNumData = criteria.data.data;
      this.classNums =
        this.classNumsList.length &&
        this.classNumsList.map((item) => item.name).toString();
      this.typeChange();
      this.getschoolreportclasscompetitiveData();
      // this.classscoredistributionData();
    },
    async classscoredistributionData() {
      // 获取班级成绩分布
      if (this.search.high) {
        this.loading = true;
        try {
          let res;
          if (this.$route.query.examType == 1) {
            res = await unionClassscoredistribution({
              subjectId: this.subjectId,
              id: this.statId,
              ...this.search,
              viewSchoolId: this.$route.query.schoolId,
            });
            this.loading = false;
          } else {
            res = await classscoredistribution({
              subjectId: this.subjectId,
              id: this.statId,
              ...this.search,
            });
          }
          let data = res.data.data.map((item, index) => {
            return item.classSegmentVOs.map((it, ind) => {
              return [index, ind, it.total];
            });
          });
          const totalData = data.flat().map((item) => item[2]);
          this.heatData = {
            xAxis: res.data.data.map((item) => item.name),
            yAxis:
              res.data.data.length &&
              res.data.data[0].classSegmentVOs.map((item) => {
                if (
                  item.name.indexOf("以上") != -1 ||
                  item.name.indexOf("以下") != -1
                ) {
                  return item.name;
                }
                return `${item.name}分`;
              }),
            data: data.flat(),
            max: Math.max(...totalData),
            min: Math.min(...totalData),
          };
          let that = this;
          this.tooltip = {
            trigger: "item",
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: "shadow",
              shadowStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },

            formatter: function (val) {
              return `${that.heatData.xAxis[val.value[0]]}：${val.value[2]}人`;
            },
          };
          this.loading = false;
          this.chartKey = Math.random();
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else if (this.search.type == 1 && !this.search.high) {
        this.$alert("请输入排名区间", "", {
          confirmButtonText: "确定",
          callback: () => {},
        });
        this.heatData = {
          xAxis: [],
          yAxis: [],
          data: [],
          max: 0,
          min: 0,
        };
        this.chartKey = Math.random();
      }
    },
    async getschoolreportclasscompetitiveData() {
      // 获取班级竞争力数据
      this.category = 1;
      let res;
      if (this.$route.query.examType == 1) {
        res = await UnionGetschoolreportclasscompetitive({
          id: this.statId,
          subjectId: this.subjectId,
          classNums: this.classNums,
          viewSchoolId: this.$route.query.schoolId,
        });
        this.schoolData = res.data.data.classVOs.find(
          (item) => item.classNum == "本校"
        );
        this.totalData = res.data.data.classVOs.find(
          (item) => item.classNum == "整体"
        );
        this.classData = res.data.data.classVOs.filter(
          (item) => !["本校", "整体"].includes(item.classNum)
        );
        let data = this.classData.slice(0, 10);
        this.tableData = JSON.parse(JSON.stringify(data));
        if (
          this.$route.query.examType == 1 &&
          this.topData.isSchoolDisplayReportUnionAverageScore == 1
        ) {
          this.tableData = [...this.tableData, this.schoolData, this.totalData];
        } else {
          this.tableData = [...this.tableData, this.schoolData];
        }
      } else {
        res = await getschoolreportclasscompetitive({
          id: this.statId,
          subjectId: this.subjectId,
          classNums: this.classNums,
        });
        this.schoolData = res.data.data.classVOs.find(
          (item) => item.classNum == "我校"
        );
        this.classData = res.data.data.classVOs.filter(
          (item) => item.classNum != "我校"
        );
        let data = this.classData.slice(0, 10);
        this.tableData = JSON.parse(JSON.stringify(data));
        this.tableData = [...this.tableData, this.schoolData];
      }
      const { overview1, overview2, overview3, overview4 } = res.data.data;
      this.textObj = {
        overview1: overview1,
        overview2: overview2,
        overview3: overview3,
        overview4: overview4 ? overview4 : "",
      };
      this.loading = false;
      if (this.tableData.length) {
        this.topSections = this.tableData[0].topSections;
        this.onlines = this.tableData[0].onlines;
        this.ratios = this.tableData[0].ratios;
        this.averageScoreFilter();
      }

      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    async handleClick() {
      const subject = this.tabs.find((item) => item.id == this.subjectId);
      if (subject) {
        this.search.high = subject.segmentMaximum;
        this.search.low = subject.segmentMinimum;
        this.search.interval = subject.segmentGap;
      }
      const res = await this.getstatclassoptionData();
      this.classNumsList = res.data.data;
      this.classNums =
        this.classNumsList.length &&
        this.classNumsList.map((item) => item.name).toString();
      // this.getschoolreportclasscompetitiveData();
      if (this.subjectId === "0") {
        this.classType = 1;
        this.classTypeOptions();
      }
      this.classscoredistributionData();
    },
    getstatInfoData() {
      // 获取统计科目
      return getstatsubjectoption({
        id: this.$route.query.id,
        requestPage: 1,
        hasTotal: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
    },
    getstatclassoptionData() {
      //校级报告-班级竞争力-查询条件(科目和班级)
      return getstatclassoption({
        id: this.statId,
        subjectId: this.subjectId,
        requestPage: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .tag {
    font-size: 14px;
    border: 1px dashed #2474ed;
    color: #737677;
    background-color: #f2f8fe;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 24px;
    line-height: 1.5;

    div {
      margin-bottom: 12px;
    }

    & :last-child {
      margin: 0;
    }
  }

  .tableList {
    margin-bottom: 32px;
    width: 100%;

    ::v-deep .hover-row {
      .el-table__cell {
        background-color: #fff5d7 !important;
      }
    }
  }

  .chart-box {
    padding: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;

    .operation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .selects {
        display: flex;
        align-items: center;

        &-item {
          display: flex;
          align-items: center;
          margin-right: 25px;

          .label {
            font-size: 14px;
            color: #080a09;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.operation-brach {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filter {
    display: flex;
    align-items: center;
    color: #5e6166;

    font-size: 14px;

    .label {
      color: #080a09;
      font-size: 14px;
      margin-right: 24px;
    }

    .filter-item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      font-size: 14px;
      color: #5e6166;

      .el-input {
        margin: 0 8px;
      }

      @media screen and (max-width: 1400px) {
        margin-bottom: 24px;
      }
    }
  }
}

::v-deep .el-tabs__nav-wrap::after {
  display: none;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}

::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}

::v-deep .el-icon-arrow-left {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

::v-deep .el-icon-arrow-right {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rows {
    display: flex;
    align-items: center;
    .row {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;
        color: #080a09;
      }
    }
    .row + .row {
      margin-left: 8px;
    }
  }
}

::v-deep .el-select-dropdown {
  display: none !important;
  opacity: 0 !important;
}

::v-deep .el-popper {
  display: none !important;
  opacity: 0 !important;
}

.el-table {
  &::v-deep {
    .el-table__body-wrapper::-webkit-scrollbar {
      /*width: 0;宽度为0隐藏*/
      height: 10px;
    }

    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 8px;
      height: 8px;
      background: #a1a1a1; //滚动条颜色
    }

    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(241, 240, 245, 0.2);
      background: #ececec; //滚动条背景色
      height: 8px;
    }
  }
}

.heatData {
  padding: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
}

.more {
  text-align: center;
  margin-bottom: 10px;
}

::v-deep .el-table__fixed {
  height: calc(100% - 10px) !important;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;

  &:hover {
    color: #fff;
  }
}

::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
