<template>
  <div
    ref="DevelopAnalysis"
    v-lazyChart="{ fn: getConditions }"
    class="card container"
  >
    <div class="section-title">发展性分析</div>

    <examTransfer
      subject
      :exam-arr="examArr"
      :stat-id="statId"
      style="margin-bottom: 10px"
      :request-page="1"
      @submit="getExamId"
    />
    <div class="operation">
      <div style="display: flex">
        <div class="selects-item" style="margin-right: 10px">
          <div class="label">选择班级：</div>
          <check-component
            :source="classNumList"
            :placeholder="'选择班级'"
            @change="getCheckClassNum"
          />
        </div>
        <div
          v-if="
            (examType == 1 && [3, 4].includes(category)) ||
            (examType != 1 && category != 1 && category != 5)
          "
          class="selects-item"
        >
          <div class="label">选择指标：</div>
          <el-select
            v-model="type"
            style="width: 174px"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="item in options"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="category === 5" class="selects-item">
          <div class="label">选择指标：</div>
          <el-select
            v-model="otherType"
            style="width: 174px"
            placeholder="请选择"
            @change="otherSelectChange"
          >
            <el-option
              v-for="item in otherOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-radio-group v-model="category" size="small" @change="radioChange">
        <el-radio-button :label="1">超均率（校）</el-radio-button>
        <el-radio-button v-if="examType == 1" :label="2"
          >超均率（整体）</el-radio-button
        >
        <el-radio-button v-else :label="2">上线情况</el-radio-button>
        <el-radio-button :label="3">比率</el-radio-button>
        <el-radio-button :label="4">前N名</el-radio-button>
        <el-radio-button :label="5">其他指标</el-radio-button>
      </el-radio-group>
    </div>
    <div class="chart-box">
      <div class="chart">
        <bar-chart :data="chartOptions" :height="500" />
      </div>
      <div class="model-desc mt30">
        <div>折线图——班级历次考试指标值</div>
        <div>
          1.
          同色折线不同考试节点对比：最近一次考试与历次考试进行对比，用于查看班级考试指标的变化趋势。
        </div>
        <div>
          2.
          同一考试节点不同颜色值对比：每次考试各班级间指标的差距。可选择同比、环比及周期性对比的角度进行发展性的分析。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import examTransfer from "../../components/examTransfer.vue";
import CheckComponent from "./checkComponent.vue";
import barChart from "./chart.vue";
import { getschoolreportdevelopanalysis } from "@/core/api/academic/schoolReportDetail";
import { getstatclassoption } from "@/core/api/academic/common";
import { Uniongetschoolreportdevelopanalysis } from "@/core/api/academic/unionReport";
import { dataMethod } from "./methods";

export default {
  name: "DevelopAnalysis",
  components: {
    examTransfer,
    barChart,
    CheckComponent,
  },
  props: {
    topData: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      category: 1,
      classNumsList: [],
      topSections: [],
      onlines: [],
      ratios: [],
      options: [],
      statId: this.$route.query.id,
      examType: this.$route.query.examType,
      spanObj: "",
      old: [],
      type: `超均率（校）`,
      field: "",
      limit: 5,
      chartOptions: {},
      otherOptions: [],
      otherType: "",
      examList: [],
      checkedExam: "",
      examName: "",
      classNums: "",
      settingName: "",
      examArr: [],
      classArr: [],
      loading: false,
      classNumList: [],
    };
  },
  methods: {
    async getConditions(loadingInstance, io) {
      const res = await getstatclassoption({
        id: this.statId,
        requestPage: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNums = this.classNumList = res.data.data.map(
        (item) => item.name
      );
      this.getschoolreportdevelopanalysisData();
      this.$nextTick(() => {
        loadingInstance.close();
        io.unobserve(this.$refs.DevelopAnalysis);
      });
    },
    otherSelectChange() {
      let reverse = [...this.examArr].reverse();
      const xAxis = reverse.map((item) => item.name + "\n" + item.examDate);
      const data = reverse.flatMap((item) => item.classes);
      let name = this.otherOptions.find(
        (item) => item.value === this.otherType
      ).label;
      const series = this.classNums.map((item) => {
        const sameClass = data.filter((it) => it.classNum === item);
        return {
          name: item + name,
          type: "line",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: sameClass.map((item) => item[this.otherType]),
        };
      });
      this.chartOptions = {
        legend: {
          itemGap: 20,
          type: "scroll",
          orient: "horizontal",
        },
        grid: {
          bottom: xAxis.length > 25 ? "30%" : "15%",
        },
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 25),
            xAxisIndex: [0],
            left: "center",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: 20,
            },
          },
        ],
        series: [...series],
      };
    },
    getExamId(val) {
      this.checkedExam = val.toString();
      this.category = 1;
      this.getschoolreportdevelopanalysisData();
    },
    selectChange() {
      this.setOptions();
    },
    setOptions() {
      let reverse = [...this.examArr].reverse();
      const xAxis = reverse.map((item) => item.name + "\n" + item.examDate);
      const data = reverse.flatMap((item) => item.classes);
      const series = this.classNums.map((item) => {
        const a = data.filter((it) => item === it.classNum);
        const fin = a.map((i) => {
          const itemData = i[this.field].find((n) => n.name === this.type);
          return this.field === "topSections"
            ? itemData.total
            : itemData.proportion;
        });
        return {
          name: item + this.type,
          type: "line",

          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: fin,
        };
      });

      this.chartOptions = {
        legend: {
          itemGap: 20,
          type: "scroll",
          orient: "horizontal",
        },
        grid: {
          bottom: xAxis.length > 25 ? "30%" : "15%",
        },
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 25),
            xAxisIndex: [0],
            left: "center",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: 20,
              fontSize: 12,
            },
          },
        ],
        series: series,
      };
    },
    objectSpanMethod({ column, columnIndex, rowIndex }) {
      if (columnIndex === 0) {
        let _row = this.spanObj[column.property][rowIndex];
        let _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    async getschoolreportdevelopanalysisData() {
      this.loading = true;
      try {
        let res;
        if (this.$route.query.examType == 1) {
          res = await Uniongetschoolreportdevelopanalysis({
            id: this.statId,
            classNums: this.classNums.toString(),
            isAllClass: this.classNums ? 0 : 1,
            viewSchoolId: this.$route.query.schoolId,
            contrastStatId: this.checkedExam,
          });
        } else {
          res = await getschoolreportdevelopanalysis({
            id: this.statId,
            classNums: this.classNums.toString(),
            isAllClass: this.classNums ? 0 : 1,
            contrastStatId: this.checkedExam,
          });
        }
        let arr = res.data.data.map((item) => {
          return item.classes.map((it) => {
            return {
              name: item.name,
              id: item.id,
              ...it,
            };
          });
        });
        this.tableData = arr.flat();
        this.spanObj = dataMethod(this.tableData, ["name"]);
        if (res.data.data.length) {
          this.topSections =
            this.tableData.length && this.tableData[0].topSections;
          this.onlines = this.tableData.length && this.tableData[0].onlines;
          this.ratios = this.tableData.length && this.tableData[0].ratios;
        }
        this.examArr = res.data.data;
        this.classArr =
          res.data.data.length &&
          res.data.data[0].classes.map((item) => item.classNum);
        this.averageScoreFilter();
      } catch {
        this.loading = false;
      }
    },
    filterData(data) {
      let tableData = data.map((item) => {
        return item.competitiveVOS.map((it) => {
          return { name: item.name, ...it };
        });
      });
      return tableData.flat();
    },
    getCheckClassNum(val) {
      this.classNums = val;
      switch (this.category) {
        case 1:
          this.getschoolreportdevelopanalysisData();
          break;
        case 2:
          if (this.examType == 1) {
            this.getschoolreportdevelopanalysisData();
          } else {
            this.selectChange();
          }
          break;
        case 3:
          this.selectChange();
          break;
        case 4:
          this.selectChange();
          break;
        case 5:
          this.otherSelectChange();
          break;
      }
    },
    averageScoreFilter() {
      let reverse = [...this.examArr].reverse();
      const xAxis = reverse.map((item) => item.name + "\n" + item.examDate);
      const data = reverse.flatMap((item) => item.classes);
      const series = this.classNums.map((item) => {
        const sameClass = data.filter((it) => it.classNum === item);
        return {
          name:
            item +
            (this.examType == 1 && this.category == 2
              ? "超均率（整体）"
              : "超均率（校）"),
          type: "line",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: sameClass.map((item) =>
            this.examType == 1 && this.category == 2
              ? item.overAverageRateUnion
              : item.overAverageRate
          ),
        };
      });
      this.chartOptions = {
        legend: {
          itemGap: 20,
          type: "scroll",
          orient: "horizontal",
        },
        grid: {
          bottom: xAxis.length > 25 ? "30%" : "15%",
        },
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: 20,
            },
          },
        ],
        series: [...series],
      };
    },
    radioChange() {
      switch (this.category) {
        case 1:
          this.type = "超均率（校）";
          this.getschoolreportdevelopanalysisData();
          break;
        case 2:
          if (this.examType == 1) {
            this.type = "超均率（整体）";
            this.getschoolreportdevelopanalysisData();
          } else {
            this.field = "onlines";
            if (this.tableData.length) {
              this.type = this.tableData[0].onlines[0].name;
              this.options = this.tableData[0].onlines;
            }
            this.selectChange();
          }
          break;
        case 3:
          this.field = "ratios";
          if (this.tableData.length) {
            this.type = this.tableData[0].ratios[0].name;
            this.options = this.tableData[0].ratios;
          }
          this.selectChange();
          break;
        case 4:
          this.field = "topSections";
          if (this.tableData.length) {
            this.type = this.tableData[0].topSections[0].name;
            this.options = this.tableData[0].topSections;
          }
          this.selectChange();
          break;
        case 5:
          if (this.$route.query.examType == 1) {
            this.otherOptions = [
              {
                label: "最高分",
                value: "highestScore",
              },
              {
                label: "最低分",
                value: "lowestScore",
              },
              {
                label: "标准差",
                value: "standardDeviation",
              },
            ];
          } else {
            this.otherOptions = [
              {
                label: "最高分",
                value: "highestScore",
              },
              {
                label: "最低分",
                value: "lowestScore",
              },
              {
                label: "全距",
                value: "rangeScore",
              },
              {
                label: "中位数",
                value: "median",
              },
              {
                label: "离均差（校）",
                value: "deviationFromMean",
              },
              {
                label: "标准差",
                value: "standardDeviation",
              },
              {
                label: "差异系数",
                value: "coefficientOfVariation",
              },
            ];
          }
          this.otherType = "highestScore";
          this.otherSelectChange();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .tag {
    font-size: 14px;
    border: 1px dashed #2474ed;
    color: #737677;
    background-color: #f2f8fe;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 24px;

    div {
      margin-bottom: 12px;
    }

    & :last-child {
      margin: 0;
    }
  }

  .filter {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;

    .filter-item {
      display: flex;
      align-items: center;
      margin-right: 24px;

      span {
        margin: 0 8px;
      }

      .label {
        color: #080a09;
        font-size: 14px;
        margin-right: 8px;
      }
    }
  }

  .section-sub-title {
    font-size: 16px;
    color: #333333;
    font-weight: 550;
    margin-bottom: 24px;
  }

  .tableList {
    margin-bottom: 32px;
    ::v-deep .hover-row {
      .el-table__cell {
        background-color: #fff5d7;
      }
    }
  }

  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.selects-item {
  display: flex;
  align-items: center;
  margin-right: 25px;

  .label {
    font-size: 14px;
    color: #080a09;
    margin-right: 8px;
  }
}

.el-table {
  &::v-deep {
    .el-table__body-wrapper::-webkit-scrollbar {
      /*width: 0;宽度为0隐藏*/
      height: 10px;
    }

    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 8px;
      height: 8px;
      background: #a1a1a1; //滚动条颜色
    }

    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(241, 240, 245, 0.2);
      background: #ececec; //滚动条背景色
      height: 8px;
    }
  }
}

::v-deep .el-table__fixed {
  height: calc(100% - 10px) !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;
  &:hover {
    color: #fff;
  }
}
::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
