<template>
  <div ref="Contribution" v-loading="loading" class="card contribution">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 4,
      }"
    >
      <div class="section-title">学科贡献分析</div>
    </exportBtn>
    <div class="filter">
      <div class="section-sub-title">年级组学科贡献</div>
      <div class="row">
        <div class="label">选择段次：</div>
        <el-select
          v-model="level"
          style="width: 112px"
          placeholder="请选择"
          @change="getSchoolReportSubjectContributionData"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="tableList">
      <el-table
        ref="elTable"
        :data="gradeList"
        :header-cell-style="{ background: '#f5f7fa' }"
        border
        style="width: 100%"
      >
        <el-table-column prop="subjectName" label="学科" align="center">
        </el-table-column>
        <el-table-column prop="total" label="参考人数" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.examTotal || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="examTotal" label="上线人数" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.total || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="proportion"
          label="上线率（%）"
          width="120"
          align="center"
        >
          <template slot-scope="{ row }">
            <span> {{ row.proportion ? row.proportion : "0" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="effectiveTotal"
          label="有效上线人数"
          width="150"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{
              row.effectiveTotal
                ? row.effectiveTotal
                : row.subjectName == "总分"
                ? "-"
                : "0"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="effectiveRate"
          label="命中率（有效上线率）（%）"
          width="220"
          align="center"
        >
          <template slot-scope="{ row }">
            {{
              row.effectiveRate
                ? row.effectiveRate
                : row.subjectName == "总分"
                ? "-"
                : "0"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="contributionRate"
          width="120"
          label="贡献率（%）"
          align="center"
        >
          <template slot-scope="{ row }">
            {{
              row.contributionRate
                ? row.contributionRate
                : row.subjectName == "总分"
                ? "-"
                : "0"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="contributionLevel"
          label="贡献等级"
          align="center"
        >
          <template slot-scope="{ row }">
            <span :class="row.contributionLevel">{{
              row.contributionLevel ? row.contributionLevel : "-"
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tag">
      <div>
        <span class="A">A</span>:命中率 ≥ 60%，贡献率 ≥
        70%&nbsp;&nbsp;&nbsp;&nbsp;结论：单科与总分上线匹配较好
      </div>
      <div>
        <span class="B">B</span>:命中率 &lt; 60%，贡献率 ≥
        70%&nbsp;&nbsp;&nbsp;&nbsp;结论：单科上线优势或单科冒进 原因：1.
        单科教学有效性高 2. 单科投入过多
      </div>
      <div>
        <span class="C">C</span>:命中率 ≥ 60%，贡献率 &lt;
        70%&nbsp;&nbsp;&nbsp;&nbsp;结论：单科滞后 原因：1. 单科教学有效性较低 2.
        单科投入不足
      </div>
      <div>
        <span class="D">D</span>:命中率 &lt; 60%，贡献率 &lt;
        70%&nbsp;&nbsp;&nbsp;&nbsp;结论：单科上线与总分上线匹配不好
      </div>
      <div>注：1.本报告中上线有关计算的基数均为参考人数。</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.有效上线人数指单科与总分同时上线的人数。命中率为单科有效上线人数/单科上线人数；非选考科目的贡献率为单科有效上线人数/总分上线人数，选考科目的贡献率为单科有效上线人数/选考该科且总分上线人数。
      </div>
    </div>

    <template v-if="(topData.statClass == 1 && examType == 1) || examType != 1">
      <div class="filter">
        <div class="section-sub-title">班级学科贡献</div>
        <div class="row">
          <div class="label">班级选择：</div>
          <check-component
            :source="classNumList"
            :placeholder="'选择班级'"
            @change="checkChange"
          />
        </div>
      </div>
      <div class="tableList">
        <el-table
          ref="classList"
          :data="classList"
          :header-cell-style="{ background: '#f5f7fa' }"
          style="width: 100%"
          :span-method="objectSpanMethod"
          border
        >
          <el-table-column
            :key="Math.random()"
            prop="classNum"
            label="班级"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            prop="teacherName"
            label="班主任"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            prop="subjectName"
            label="学科"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            prop="examTotal"
            label="参考人数"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>{{ row.examTotal || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            prop="total"
            label="上线人数"
            align="center"
          >
            <template slot-scope="{ row }">
              <span>{{ row.total || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            prop="proportion"
            label="上线率（%）"
            width="120"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.proportion ? row.proportion : "0" }}
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            prop="effectiveTotal"
            label="有效上线人数"
            align="center"
            width="150"
          >
            <template slot-scope="{ row }">
              {{
                row.effectiveTotal
                  ? row.effectiveTotal
                  : row.subjectName == "总分"
                  ? "-"
                  : 0
              }}
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            prop="effectiveRate"
            label="命中率（有效上线率）（%）"
            align="center"
            width="220"
          >
            <template slot-scope="{ row }">
              {{
                row.effectiveRate
                  ? row.effectiveRate
                  : row.subjectName == "总分"
                  ? "-"
                  : 0
              }}
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            prop="contributionRate"
            width="120"
            label="贡献率（%）"
            align="center"
          >
            <template slot-scope="{ row }">
              {{
                row.contributionRate
                  ? row.contributionRate
                  : row.subjectName == "总分"
                  ? "-"
                  : 0
              }}
            </template>
          </el-table-column>
          <el-table-column
            :key="Math.random()"
            prop="contributionLevel"
            label="贡献等级"
            align="center"
          >
            <template slot-scope="{ row }">
              <span :class="row.contributionLevel">{{
                row.contributionLevel ? row.contributionLevel : "-"
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="cacheClassList.length >= 10" class="more">
        <el-button type="text" @click="extendChange">{{
          isExtend ? "收起" : "展开全部"
        }}</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import {
  getSchoolReportSubjectContribution,
  getstatInfo,
  exportSchoolReport,
} from "@/core/api/academic/schoolReportDetail";
import exportBtn from "@/views/academic/components/exportBtn";
import { UniongetSchoolReportSubjectContribution } from "@/core/api/academic/unionReport";
import checkComponent from "./checkComponent.vue";
import {
  getstatonlineoption,
  getstatclassoption,
} from "@/core/api/academic/common";
import { dataMethod } from "./methods";
import axios from "axios";
export default {
  name: "Contribution",
  components: {
    checkComponent,
    exportBtn,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      requestFunc: exportSchoolReport,
      statId: this.$route.query.id,
      options: [],
      classOptions: [],
      level: "",
      classLevel: "",
      tableData: [],
      gradeList: [],
      classList: [],
      subjectList: [],
      classNumList: [],
      classNum: "",
      old: [],
      cacheClassList: [],
      isExtend: false,
      loading: false,
      examType: this.$route.query.examType,
    };
  },
  mounted() {
    this.getstatInfoData();
    this.getData();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.classList.doLayout();
    });
  },
  methods: {
    objectSpanMethod({ columnIndex, column, rowIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        let _row = this.spanObj[column.property][rowIndex];
        let _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    checkChange(val) {
      this.classNum = val.toString();
      this.getSchoolReportSubjectContributionData();
    },

    getLevelColor(row) {
      if (row.onlineRateEffective >= 60 && row.contributionRate >= 70) {
        return "A";
      } else if (row.onlineRateEffective < 60 && row.contributionRate >= 70) {
        return "B";
      } else if (row.onlineRateEffective >= 60 && row.contributionRate < 70) {
        return "C";
      } else if (row.onlineRateEffective < 60 && row.contributionRate < 70) {
        return "D";
      }
    },
    async getSchoolReportSubjectContributionData() {
      this.classList = [];
      try {
        let res;
        if (this.$route.query.examType == 1) {
          res = await UniongetSchoolReportSubjectContribution({
            id: this.statId,
            onlineId: this.level,
            viewSchoolId: this.$route.query.schoolId,
            classNums: this.classNum,
          });
        } else {
          res = await getSchoolReportSubjectContribution({
            id: this.statId,
            onlineId: this.level,
            classNums: this.classNum,
          });
        }
        const { classes, subjects } = res.data.data;
        this.gradeList = subjects;

        let arr = classes.map((item) => {
          return item.subjects.map((it) => {
            return {
              ...it,
              teacherName: item.teacherName || "-",
              classNum: item.classNum,
            };
          });
        });
        this.cacheClassList = arr.flat();
        this.classList = this.isExtend
          ? [...this.cacheClassList]
          : this.cacheClassList.slice(0, 10);
        this.spanObj = dataMethod(this.classList, ["classNum", "teacherName"]);
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
          this.$refs.classList.doLayout();
        });
      } catch {
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
          this.$refs.classList.doLayout();
        });
      }
    },
    extendChange() {
      if (this.isExtend) {
        this.classList = this.cacheClassList.slice(0, 10);
        this.isExtend = false;
        this.spanObj = dataMethod(this.classList, ["classNum", "teacherName"]);
        this.$nextTick(() => {
          this.$refs.classList.doLayout();
        });
      } else {
        this.classList = [...this.cacheClassList];
        this.spanObj = dataMethod(this.classList, ["classNum", "teacherName"]);
        this.isExtend = true;
        this.$nextTick(() => {
          this.$refs.classList.doLayout();
        });
      }
    },
    async getData() {
      this.loading = true;
      try {
        const [onlineRes, classRes] = await axios.all([
          getstatonlineoption({
            id: this.statId,
          }),
          getstatclassoption({
            id: this.statId,
            requestPage: 1,
            viewSchoolId: this.$route.query.schoolId,
          }),
        ]);
        this.options = onlineRes.data.data;
        if (onlineRes.data.data && onlineRes.data.data.length) {
          this.level = onlineRes.data.data && onlineRes.data.data[0].id;
        }
        this.classNumList = classRes.data.data.map((item) => item.name);
        this.classNum = this.classNumList.toString();
        if (this.options.length) {
          this.getSchoolReportSubjectContributionData();
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    assembleClassList(arr) {
      return arr.map((item) => {
        return item.contributionGradeBaseVOList.map((it) => {
          return {
            classNum: item.classNum,
            teacherName: item.teacherName || "",
            ...it,
          };
        });
      });
    },
    async getstatInfoData() {
      // 获取统计科目
      const res = await getstatInfo({ id: this.statId });
      this.subjectList = res.data.data.statSubjects;
    },
    getSubjectName(id) {
      if (!Number(id)) {
        return "总分";
      } else {
        const data = this.subjectList.find((item) => item.subjectId === id);
        return data.name;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contribution {
  .filter {
    margin: 28px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .row {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;

        color: #080a09;
      }
    }
  }

  .tableList {
    margin-bottom: 32px;

    ::v-deep tbody tr:hover > td {
      background-color: #fff5d7;
    }
  }

  .more {
    text-align: center;
  }
}

.tag {
  font-size: 14px;
  border: 1px dashed #2474ed;
  color: #737677;
  background-color: #f2f8fe;
  box-sizing: border-box;
  padding: 12px;
  margin-bottom: 24px;
  line-height: 1.5;

  div {
    // margin-bottom: 12px;
    line-height: 24px;
  }

  & :last-child {
    margin: 0;
  }
}

.A {
  color: #60d598;
}

.B {
  color: #63a8ea;
}

.C {
  color: #f1815b;
}

.D {
  color: #ff0001;
}

.el-table {
  &::v-deep {
    .el-table__body-wrapper::-webkit-scrollbar {
      /*width: 0;宽度为0隐藏*/
      height: 10px;
    }

    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 8px;
      height: 8px;
      background: #a1a1a1; //滚动条颜色
    }

    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(241, 240, 245, 0.2);
      background: #ececec; //滚动条背景色
      height: 8px;
    }
  }
}

::v-deep .el-table__fixed {
  height: calc(100% - 10px) !important;
}
</style>
