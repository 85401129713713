<template>
  <div
    ref="bar"
    class="bar"
    :style="{
      height: height + 'px',
    }"
  ></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Bar",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    height: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        legend: { itemGap: 30 },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        series: [],
      },
    };
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          this.initChart(val);
        });
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.data) {
        this.initChart(this.data);
      }
    });
  },
  methods: {
    initChart(val) {
      let options = {};
      let charts = echarts.init(this.$refs.bar, null, {
        devicePixelRatio: 2.5,
      });
      options = { ...this.option, ...val };
      this.$refs.bar.setAttribute("_echarts_instance_", "");
      charts.setOption(options, true);
      setTimeout(() => {
        window.onresize = () => {
          charts.resize();
        };
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.bar {
  width: 100%;
}
</style>
