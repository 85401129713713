const track = [
  {
    label: "姓名",
    prop: "name",
    fixed: true,
    width: 75,
  },
  {
    label: "班级",
    prop: "classNum",
    fixed: true,
    width: 75,
  },
  {
    label: "学科薄弱程度",
    prop: "littleSubject",
    fixed: true,
  },
];
const critical = [
  {
    label: "班级",
    prop: "classNum",
  },
  {
    label: "班主任",
    prop: "teacherName",
  },

  {
    label: "参考人数",
    prop: "students",
  },
  {
    label: "上线人数",
    prop: "onlineStudents",
  },
  {
    label: "上线率",
    prop: "onlineRate",
  },
  {
    label: "上线稳定性",
    prop: "onlineStability",
  },
];
const topWard = [
  {
    label: "班级",
    prop: "classNum",
  },
  {
    label: "班主任",
    prop: "teacherName",
  },

  {
    label: "统计人数",
    prop: "statTotal",
  },
];
const wave = [
  {
    label: "班级",
    prop: "classNum",
  },
  {
    label: "班主任",
    prop: "teacherName",
  },

  {
    label: "统计人数",
    prop: "students",
  },
];

export { track, critical, topWard, wave };
