<template>
  <div ref="FocusOnStudent" v-loading="loading" class="card focusOnStudent">
    <div class="section-title">重点关注学生</div>
    <div class="radio">
      <el-radio-group v-model="radio" size="mini" @change="radioChange">
        <el-radio-button
          v-if="
            (examType == 1 && topData.topInfoOnlineVOs.length) || examType != 1
          "
          :label="2"
          >临界生</el-radio-button
        >
        <el-radio-button v-if="showStudentRank()" :label="3"
          >波动生</el-radio-button
        >
        <el-radio-button v-if="showStudentRank()" :label="4"
          >拔尖生</el-radio-button
        >
        <el-radio-button v-if="showStudentRank()" :label="5"
          >后进生</el-radio-button
        >
        <el-radio-button :label="1">跟踪生</el-radio-button>
      </el-radio-group>
    </div>
    <div class="chart-box mt10">
      <div>
        <div v-if="radio === 2 && levelOptions.length" class="filter">
          <div class="filter-item">
            <div class="label">选择段次：</div>
            <el-select
              v-model="search.onlineId"
              style="width: 174px"
              placeholder="请选择"
              @change="validCritical"
            >
              <el-option
                v-for="item in levelOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="filter-item">
            <div class="label">临界分数：</div>
            <el-input
              v-model="search.interval"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 72px"
              maxlength="5"
              @blur="search.interval = $event.target.value"
            ></el-input>
          </div>
          <el-button
            type="primary"
            style="margin-left: 10px"
            plain
            @click="validCritical"
            >确定</el-button
          >
        </div>
        <div v-if="radio == 3" class="filter">
          <div class="filter-item">
            <div class="label">校排名前：</div>
            <el-input
              v-model="wave.upTotal"
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="5"
              style="width: 72px"
              @blur="wave.upTotal = $event.target.value"
            ></el-input>
            <span>名中，与选中考试对比进步最大的前</span>
            <el-input
              v-model="wave.upMax"
              style="width: 72px"
              maxlength="5"
              oninput="value=value.replace(/[^\d]/g,'')"
              @blur="wave.upMax = $event.target.value"
            ></el-input>
            <span>名。</span>
            <el-select
              v-model="wave.upCompareId"
              style="width: 400px"
              clearable
              placeholder="请选择"
              @change="validateWave"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button type="primary" plain @click="validateWave">确定</el-button>
        </div>
        <div v-if="radio === 4" class="filter">
          <div class="filter-item">
            <div class="label">校排名前：</div>
            <el-input
              v-model.number="topOrBackward"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 72px"
              maxlength="5"
              @blur="topOrBackward = $event.target.value"
            ></el-input>
            <el-button
              type="primary"
              plain
              style="margin-left: 15px"
              @click="validToporbackward"
              >确定</el-button
            >
          </div>
        </div>
        <div v-if="radio === 5" class="filter">
          <div class="filter-item">
            <div class="label">校排名后：</div>
            <el-input
              v-model.number="topOrBackward"
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="5"
              style="width: 72px"
              @blur="topOrBackward = $event.target.value"
            ></el-input>
            <el-button
              type="primary"
              plain
              style="margin-left: 15px"
              @click="validToporbackward"
              >确定</el-button
            >
          </div>
        </div>
        <bar-chart
          v-if="chartOptions"
          style="margin-bottom: 24px"
          :data="chartOptions"
        />
        <div v-if="radio === 3" class="filter">
          <div class="filter-item">
            <div class="label">校排名前：</div>
            <el-input
              v-model="backWave.downTotal"
              maxlength="5"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 72px"
              @blur="backWave.downTotal = $event.target.value"
            ></el-input>
            <span>名中，与选中考试对比退步最大的前</span>
            <el-input v-model="backWave.downMax" style="width: 72px"></el-input>
            <span>名。</span>
            <el-select
              v-model="backWave.downCompareId"
              style="width: 400px"
              clearable
              placeholder="请选择"
              @change="validateBackWava"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button type="primary" plain @click="validateBackWava"
            >确定</el-button
          >
        </div>
        <template v-if="radio == 3">
          <bar-chart
            v-if="waveBackChartOptions"
            :data="waveBackChartOptions"
            style="margin-bottom: 24px"
          />
          <no-data v-else></no-data>
        </template>
        <exportBtn
          :request-func="requestFunc"
          :params="{
            id: $route.query.id,
            criticalInterval: search.interval,
            type: 5,
            ...backWave,
            ...wave,
            topOrBackward: topOrBackward,
          }"
        >
          <div v-if="radio !== 1" class="filter">
            <div class="filter-item">
              <div class="label">选择班级：</div>
              <check-component
                :source="classNumsList"
                @change="getCheckClassNum"
              />
            </div>
          </div>
        </exportBtn>

        <div v-if="tableData.length" class="tableList">
          <el-table
            ref="elTable"
            :data="tableData"
            :header-cell-style="{ background: '#f5f7fa' }"
            style="width: 100%"
            border
          >
            <div v-if="radio === 1">
              <el-table-column
                v-for="(item, index) in track"
                :key="index + 'track'"
                :prop="item.prop"
                :label="item.label"
                :fixed="item.fixed"
                :width="item.prop === 'littleSubject' ? 300 : item.width || 200"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span v-if="item.prop === 'littleSubject'">
                    <span v-if="row.littleSubject.length">
                      <span class="subjectShit">{{
                        row.littleSubject.toString()
                      }}</span>
                      稍薄弱
                    </span>
                    <span
                      v-if="
                        row.littleSubject.length && row.seriousSubject.length
                      "
                      >,</span
                    >
                    <span v-if="row.seriousSubject.length">
                      <span class="subjectShit">{{
                        row.seriousSubject.toString()
                      }}</span>
                      严重偏科
                    </span>
                  </span>
                  <span v-else>{{ row[item.prop] }}</span>
                </template>
              </el-table-column>
              <div
                v-for="(item, index) in scoreInfo"
                :key="'scoreInfo' + index + 'list'"
              >
                <el-table-column label="科目" align="center" width="100">
                  <template slot-scope="{ row }">
                    {{ getSubjectName(row.subjects[index].subjectId) }}
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="$route.query.isSchoolDisplayReportStudentRank == 1"
                  label="校排名"
                  prop="rank"
                  width="100"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    {{ row.subjects[index].rank }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="任课老师"
                  prop="teacherName"
                  width="300"
                  align="center"
                  :render-header="
                    (h, { column, $index }) =>
                      renderHeader(h, { column, $index }, index)
                  "
                >
                  <template slot-scope="{ row }">
                    {{ row.subjects[index].teacherName }}
                  </template>
                </el-table-column>
              </div>
            </div>
            <template v-else-if="radio === 2">
              <el-table-column
                :key="Math.random()"
                prop="classNum"
                label="班级"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                :key="Math.random()"
                prop="teacherName"
                label="班主任"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                :key="Math.random()"
                prop="total"
                label="参考人数"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                :key="Math.random()"
                prop="onlineTotal"
                label="上线人数"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                :key="Math.random()"
                prop="onlineProportion"
                label="上线率"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                :key="Math.random()"
                prop="onlineStability"
                label="	上线稳定性"
                width="100"
                align="center"
              ></el-table-column>

              <el-table-column
                :key="Math.random()"
                label="总分临界单科薄弱"
                align="center"
              >
                <el-table-column
                  :key="Math.random()"
                  label="人数"
                  prop="weakTotal"
                  align="center"
                ></el-table-column>
                <el-table-column
                  :key="Math.random()"
                  label="占比（%）"
                  width="100"
                  prop="weakProportion"
                  align="center"
                ></el-table-column>
              </el-table-column>
              <el-table-column label="上线临界" align="center">
                <el-table-column
                  :key="Math.random()"
                  label="人数"
                  align="center"
                  prop="upTotal"
                ></el-table-column>
                <el-table-column
                  :key="Math.random()"
                  label="占比（%）"
                  width="100"
                  align="center"
                  prop="upProportion"
                ></el-table-column>
                <el-table-column
                  :key="Math.random()"
                  label="学生名单"
                  prop="upStudents"
                  align="center"
                  width="350"
                >
                  <template slot-scope="{ row }">
                    <div class="nameList">
                      <div
                        v-for="(item, index) in row.upStudents"
                        :key="'upStudents' + index"
                        type="text"
                        :class="item.type == 1 ? 'offBack' : 'on'"
                        @click="sutdentDetail(item)"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="未上线临界" align="center">
                <el-table-column
                  label="人数"
                  align="center"
                  prop="downTotal"
                ></el-table-column>
                <el-table-column
                  label="占比（%）"
                  width="100"
                  align="center"
                  prop="downProportion"
                ></el-table-column>
                <el-table-column label="学生名单" align="center" width="350">
                  <template slot-scope="{ row }">
                    <div class="nameList">
                      <div
                        v-for="(item, index) in row.downStudents"
                        :key="'downStudents' + index"
                        type="text"
                        :class="'off'"
                        @click="sutdentDetail(item)"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>
            </template>
            <template v-else-if="radio === 3">
              <el-table-column
                v-for="(item, index) in topWard"
                :key="'track' + index"
                :prop="item.prop"
                :label="item.label"
                fixed
                width="85"
                align="center"
              >
              </el-table-column>
              <el-table-column label="上升波动" align="center">
                <el-table-column label="人数" width="80" align="center">
                  <template slot-scope="{ row }">
                    {{ row.upTotal || "-" }}
                  </template>
                </el-table-column>
                <el-table-column label="占比" width="80" align="center">
                  <template slot-scope="{ row }">
                    {{ row.upProportion || "-" }}
                  </template>
                </el-table-column>
                <el-table-column label="学生名单">
                  <template slot-scope="{ row }">
                    <div class="nameList">
                      <div
                        v-for="(item, index) in row.upStudents"
                        :key="index"
                        :class="'on'"
                        @click="sutdentDetail(item)"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="下降波动" align="center">
                <el-table-column label="人数" width="80" align="center">
                  <template slot-scope="{ row }">
                    {{ row.downTotal }}
                  </template>
                </el-table-column>
                <el-table-column label="占比" width="80" align="center">
                  <template slot-scope="{ row }">
                    {{ row.downProportion || "-" }}
                  </template>
                </el-table-column>
                <el-table-column label="学生名单">
                  <template slot-scope="{ row }">
                    <div class="nameList">
                      <div
                        v-for="(item, index) in row.downStudents"
                        :key="index"
                        :class="'on'"
                        @click="sutdentDetail(item)"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>
            </template>
            <template v-else-if="radio === 4 || radio === 5">
              <el-table-column
                v-for="(item, index) in topWard"
                :key="'track' + index"
                :prop="item.prop"
                :label="item.label"
                fixed
                width="100"
                align="center"
              >
              </el-table-column>
              <el-table-column
                :label="radio === 4 ? '拔尖生' : '后进生'"
                align="center"
              >
                <el-table-column
                  :key="Math.random()"
                  label="人数"
                  prop="total"
                  align="center"
                ></el-table-column>
                <el-table-column
                  :key="Math.random()"
                  label="占比"
                  prop="proportion"
                  align="center"
                ></el-table-column>
                <el-table-column
                  :key="Math.random()"
                  :label="radio === 4 ? '优生劣科人数' : '潜在科目人数'"
                  prop="specialTotal"
                  align="center"
                ></el-table-column>
                <el-table-column
                  :key="Math.random()"
                  :label="radio === 4 ? '优生劣科占比' : '潜力科目占比'"
                  align="center"
                  prop="specialProportion"
                ></el-table-column>

                <el-table-column
                  :key="Math.random()"
                  label="学生名单"
                  width="350"
                >
                  <template slot-scope="{ row }">
                    <div class="nameList">
                      <div
                        v-for="(item, index) in row.students"
                        :key="index"
                        :class="item.type == 1 ? 'offBack' : 'on'"
                        @click="sutdentDetail(item)"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <div class="model-desc mt30">
        <div v-if="radio === 1">
          <div class="indent">
            跟踪生：仅当在报告设置中导入了跟踪生且跟踪生对应成功才显示。跟踪生（目标跟踪生）即为实现上线目标最稳定的学生群体，为目标基础保底儿。可从两方面设置
          </div>
          <div>
            一、（基于目标）结合学生入口成绩及（中）高考预期，确定学校跟踪生名单，各班数量不均。
          </div>
          <div>
            二、（基于管理）每班确定N名跟踪生，多为成绩稳定的尖子生，每班数量均匀。
          </div>
        </div>
        <div v-if="radio === 2">
          <div class="indent">
            临界生：仅当统计参数设置中进行了上线设置才显示。临界生即为处于上线边缘的学生，分为上线临界生（刚刚上线的学生）及未上线临界生（马上要上线的学生），稳定上线临界生成绩，提升未上线临界生成绩，保证上线率稳步提升。
          </div>
          <div>
            注：1.标红学生为总分临界单科薄弱学生（这里单科薄弱特指单科存在严重偏科）
          </div>
          <div class="indent">2.临界生的统计基数为参考人数</div>
        </div>
        <div v-if="radio === 3">
          <div class="indent">
            波动生：以某次考试为对比入口，检索出波动幅度较大的学生，分为上升波动（处于成绩提升趋势）及下降波动（处于成绩下降趋势）。对上升波动的学生给予肯定，激励其保持上升趋势；对下降波动学生，辅助分析原因并给予调整建议和鼓励。特别是排名靠前或临界状态的学生，可进一步查看学生报告了解历次发展情况，进行波动性深度分析。
          </div>
          <div>注：不参与统计的学生不参与此处计算，也不在此处显示</div>
        </div>
        <div v-if="radio === 4">
          <div class="indent">
            拔尖生（优生劣科）：了解拔尖生群体分布情况，以及优生劣科情况（拔尖生存在“严重偏科”的学科）。优生劣科可结合学生自身的学习特点分析，是否在本学科存在学有余力但方法不当或兴趣不高的情况，如存在应适当引导学生以总分为前提进行偏科学科的学习方法或学习状态的调整，同时也可提升班级总分高分群体占比。
          </div>
          <div>注：1.标橙学生为优生劣科学生</div>
          <div class="indent">
            2.不参与统计的学生不参与此处计算，也不在此处显示
          </div>
        </div>
        <div v-if="radio === 5">
          <div class="indent">
            后进生（潜力学科）：了解后进生群体分布情况，以及潜力学科情况（后进生存在单科排名大幅度优于总分排名的学科）。后进生的潜力学科，可依据学生情况针对性分析，是否存在学有余力但学习动力不足的问题导致仅兴趣或天赋学科成绩较好，其他学科成绩滞后的情况。如存在应采取相应手段激发学生学习动力，并给予潜力学科肯定，如给予学生该兴趣学科的生涯规划指导等。
          </div>
          <div>注：1.标蓝学生为潜力生</div>
          <div class="indent">
            2.不参与统计的学生不参与此处计算，也不在此处显示
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import barChart from "./chart.vue";
import CheckComponent from "./checkComponent.vue";
import exportBtn from "@/views/academic/components/exportBtn";

import { track, critical, topWard, wave } from "./TableLabel";
import {
  getschoolreportimportstudentTracking,
  getstatInfo,
  getschoolreportimportstudentCritical,
  getstatcomparelist,
  toporbackward,
  getschoolreportimportstudentWave,
  exportSchoolReport,
} from "@/core/api/academic/schoolReportDetail";
import {
  UnionCritical,
  Uniontracking,
  Unionwave,
  Uniontoporbackward,
} from "@/core/api/academic/unionReport";
import {
  getstatonlineoption,
  getstatclassoption,
  getstatsubjectoption,
} from "@/core/api/academic/common";

import axios from "axios";
export default {
  name: "FocusOnStudent",
  components: {
    barChart,
    CheckComponent,
    exportBtn,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      radio: 2,
      track: track,
      requestFunc: exportSchoolReport,
      topWard: topWard,
      waveLabel: wave,
      critical: critical,
      tableData: [],
      search: {
        interval: 10,
        onlineId: "",
      },
      topOrBackward: "20",
      classNumsList: [],
      options: [],
      scoreInfo: [],
      statId: this.$route.query.id,
      examType: this.$route.query.examType,
      subjectList: [],
      chartOptions: {},
      levelOptions: [],
      old: [],
      toporbackwardType: 1,
      wave: {
        upTotal: "100",
        upMax: "20",
        upCompareId: "",
      },
      backWave: {
        downTotal: "100",
        downMax: "20",
        downCompareId: "",
      },
      classNums: "",
      waveBackChartOptions: {},
      loading: false,
    };
  },

  async created() {
    const res = await getstatsubjectoption({
      id: this.$route.query.id,
      requestPage: 1,
    });
    this.subjectList = res.data.data;
    if (this.examType == 1 && this.topData.topInfoOnlineVOs.length) {
      this.radio = 2;
      this.getData();
    } else if (
      this.examType == 1 &&
      this.topData.isSchoolDisplayReportStudentRank == 1
    ) {
      this.radio = 3;
      this.radioChange();
    } else {
      this.radio = 1;
      this.radioChange();
    }
  },

  methods: {
    showStudentRank() {
      if (this.$route.query.examType == 1) {
        return this.topData.isSchoolDisplayReportStudentRank == 1;
      }
      return true;
    },
    renderHeader(h, value, index) {
      let title = this.scoreInfo[index].subjectId == 0 ? "班主任" : "任课老师";
      return h("span", {}, title);
    },
    sutdentDetail(item) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          studentId: item.studentId,
          ...this.$route.query,
          id: this.statId,
        },
      });
      window.open(href, "_blank");
    },
    async getData() {
      this.tableData = [];

      this.getCriticalConditions();
    },
    async getstatcomparelistData() {
      const [res, classRes] = await axios.all([
        getstatcomparelist({ statId: this.statId }),
        getstatclassoption({
          id: this.statId,
          viewSchoolId: this.$route.query.schoolId,
        }),
      ]);
      if (res.data.data.length) {
        this.wave.upCompareId = this.backWave.downCompareId =
          res.data.data[0].id;
        this.options = res.data.data;
        this.classNumsList = classRes.data.data.map((item) => item.name);
        this.classNums = this.classNumsList.toString();
        this.getschoolreportimportstudentTopWaveData();
      } else {
        this.chartOptions = null;
        this.waveBackChartOptions = null;
      }

      this.loading = false;
    },
    async getschoolreportimportstudentTopWaveData() {
      this.tableData = [];
      this.loading = true;
      try {
        let res;
        if (this.$route.query.examType == 1) {
          res = await Unionwave({
            ...this.backWave,
            ...this.wave,
            id: this.statId,
            classNums: this.classNums,
            viewSchoolId: this.$route.query.schoolId,
          });
        } else {
          res = await getschoolreportimportstudentWave({
            ...this.backWave,
            ...this.wave,
            id: this.statId,
            classNums: this.classNums,
          });
        }
        this.tableData = res.data.data;
        if (res.data.data.length) {
          this.chartOptions = {
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.classNum),
                axisTick: {
                  show: false,
                },
              },
            ],
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "5%",
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            series: [
              {
                name: "波动生", // 不同条柱
                type: "bar",
                label: {
                  show: true,
                  color: "inherit",
                  fontSize: 9,
                  position: "top",
                },
                barMaxWidth: 40,
                data: res.data.data.map((item) => item.upTotal || 0),
              },
            ],
          };
          this.waveBackChartOptions = {
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.classNum),
                axisTick: {
                  show: false,
                },
              },
            ],
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "5%",
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            series: [
              {
                name: "波动生", // 不同条柱
                type: "bar",
                label: {
                  show: true,
                  color: "inherit",
                  fontSize: 9,
                  position: "top",
                },
                barMaxWidth: 40,
                data: res.data.data.map((item) => item.downTotal || 0),
              },
            ],
          };
        } else {
          this.chartOptions = null;
          this.waveBackChartOptions = null;
        }

        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    getstatInfoData() {
      // 获取统计科目
      return getstatInfo({ id: this.statId });
    },
    async getschoolreportimportstudentTrackingData() {
      try {
        let track;
        if (this.$route.query.examType == 1) {
          track = await Uniontracking({
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
          });
        } else {
          track = await getschoolreportimportstudentTracking({
            id: this.statId,
          });
        }
        this.tableData = track.data.data.students;
        this.scoreInfo =
          track.data.data.students.length &&
          track.data.data.students[0].subjects;

        let valid = track.data.data.classes.every(
          (item) => item.total !== null
        );
        if (track.data.data.classes.length && valid) {
          this.chartOptions = {
            xAxis: [
              {
                type: "category",
                data: track.data.data.classes.map((item) => item.classNum),
                axisTick: {
                  show: false,
                },
              },
            ],
            grid: {
              bottom: track.data.data.length > 25 ? "20%" : "5%",
            },
            dataZoom: [
              //滚动条
              {
                show: track.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue:
                  track.data.data.length - (track.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            series: [
              {
                name: "跟踪生", // 不同条柱
                type: "bar",
                label: {
                  show: true,
                  color: "inherit",
                  fontSize: 9,
                  position: "top",
                },
                barMaxWidth: 40,
                data: track.data.data.classes.map((item) => item.total),
              },
            ],
          };
        } else {
          this.chartOptions = null;
        }

        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getToporbackwardConditions() {
      const res = await getstatclassoption({
        id: this.statId,
        requestPage: 1,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNumsList = res.data.data.map((item) => item.name);
      this.classNums = this.classNumsList.toString();
      this.getToporbackward();
    },
    validToporbackward() {
      if (!/(^[1-9]\d*$)/.test(this.topOrBackward)) {
        this.$message("排名应为正整数");
        return;
      } else {
        this.getToporbackward();
      }
    },
    validateBackWava() {
      if (
        !/(^[1-9]\d*$)/.test(this.backWave.downTotal) ||
        !/(^[1-9]\d*$)/.test(this.backWave.downMax)
      ) {
        this.$message("请输入正整数");
        return;
      } else if (!this.backWave.downCompareId) {
        this.$message({
          showClose: false,
          messasge: "请选择对比考试",
          type: "error",
        });
        this.waveBackChartOptions = null;
      } else {
        this.getschoolreportimportstudentTopWaveData();
      }
    },
    validateWave() {
      if (
        !/(^[1-9]\d*$)/.test(this.wave.upTotal) ||
        !/(^[1-9]\d*$)/.test(this.wave.upMax)
      ) {
        this.$message("请输入正整数");
        return;
      } else if (!this.wave.upCompareId) {
        this.$message({
          showClose: false,
          message: "请选择对比考试",
          type: "error",
        });
        this.chartOptions = null;
      } else {
        this.getschoolreportimportstudentTopWaveData();
      }
    },
    // 拔尖生 后进生
    async getToporbackward() {
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await Uniontoporbackward({
            type: this.toporbackwardType,
            topOrBackward: this.topOrBackward,
            id: this.statId,
            classNums: this.classNums,
            viewSchoolId: this.$route.query.schoolId,
          });
        } else {
          res = await toporbackward({
            type: this.toporbackwardType,
            topOrBackward: this.topOrBackward,
            id: this.statId,
            classNums: this.classNums,
          });
        }
        this.tableData = res.data.data;
        this.loading = false;
        if (res.data.data.length) {
          this.chartOptions = {
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.classNum),
                axisTick: {
                  show: false,
                },
              },
            ],
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "5%",
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            series: [
              {
                name: this.toporbackwardType == 1 ? "拔尖生" : "后进生", // 不同条柱
                type: "bar",
                label: {
                  show: true,
                  color: "inherit",
                  fontSize: 9,
                  position: "top",
                },
                barMaxWidth: 40,
                data: res.data.data.map((item) => item.total),
              },
            ],
          };
        } else {
          this.chartOptions = null;
        }
      } catch {
        this.loading = false;
      }
    },
    radioChange() {
      this.tableData = [];
      switch (this.radio) {
        case 1:
          this.loading = true;
          this.getschoolreportimportstudentTrackingData();
          break;
        case 2:
          this.loading = true;
          this.getCriticalConditions();
          break;
        case 3:
          this.loading = true;
          this.getstatcomparelistData();
          break;
        case 4:
          this.toporbackwardType = 1;
          this.loading = true;
          this.topOrBackward = 20;
          this.getToporbackwardConditions();
          break;
        case 5:
          this.toporbackwardType = 2;
          this.loading = true;
          this.topOrBackward = 20;
          this.getToporbackwardConditions();
          break;
      }
    },
    async getCriticalConditions() {
      this.tableData = [];
      const res = await axios.all([
        getstatclassoption({
          id: this.statId,
          requestPage: 1,
          viewSchoolId: this.$route.query.schoolId,
        }),
        getstatonlineoption({
          id: this.statId,
        }),
      ]);
      const [classRes, onlinesRes] = res;
      if (onlinesRes.data.data.length) {
        this.levelOptions = onlinesRes.data.data;
        this.classNumsList = classRes.data.data.map((item) => item.name);
        this.search.onlineId = onlinesRes.data.data[0].id;
        this.classNums = classRes.data.data.map((item) => item.name).toString();
        this.getschoolreportimportstudentCriticalData();
      } else {
        this.loading = false;
        this.tableData = [];
        this.chartOptions = null;
      }
    },
    getCheckClassNum(val) {
      this.classNums = val.toString();
      switch (this.radio) {
        case 2:
          this.search.interval = this.topData.criticalInterval;
          this.getschoolreportimportstudentCriticalData();
          break;
        case 3:
          this.getschoolreportimportstudentTopWaveData();
          break;
        case 4:
          this.toporbackwardType = 1;
          this.getToporbackward();
          break;
        case 5:
          this.toporbackwardType = 2;
          this.getToporbackward();
          break;
      }
    },
    validCritical() {
      if (!/(^[1-9]\d*$)/.test(this.search.interval)) {
        this.$message("临界分应为正整数");
        return;
      } else {
        this.getschoolreportimportstudentCriticalData();
      }
    },
    //临界生
    async getschoolreportimportstudentCriticalData() {
      this.loading = true;
      try {
        let res;
        if (this.$route.query.examType == 1) {
          res = await UnionCritical({
            id: this.statId,
            onlineId: this.search.onlineId,
            criticalInterval: this.search.interval,
            viewSchoolId: this.$route.query.schoolId,
            classNums: this.classNums,
          });
        } else {
          res = await getschoolreportimportstudentCritical({
            id: this.statId,
            onlineId: this.search.onlineId,
            criticalInterval: this.search.interval,
            classNums: this.classNums,
          });
        }
        this.tableData = res.data.data;
        this.chartOptions = {
          xAxis: [
            {
              type: "category",
              data: res.data.data.map((item) => item.classNum),
              axisTick: {
                show: false,
              },
            },
          ],
          grid: {
            bottom: res.data.data.length > 25 ? "20%" : "5%",
          },
          dataZoom: [
            //滚动条
            {
              show: res.data.data.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: res.data.data.length - (res.data.data.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          series: [
            {
              name: "上线临界", // 不同条柱
              type: "bar",
              label: {
                show: true,
                position: "top",
                color: "inherit",
                fontSize: 9,
              },
              barMaxWidth: 40,
              data: res.data.data.map((item) => item.upTotal || 0),
            },
            {
              name: "未上线临界", // 不同条柱
              type: "bar",
              color: "#F56C6C",
              label: {
                show: true,
                position: "bottom",
                color: "inherit",
                fontSize: 9,
                formatter: function (val) {
                  return Math.abs(val.data);
                },
              },
              barMaxWidth: 40,
              data: res.data.data.map((item) => {
                return -Number(item.downTotal) || 0;
              }),
            },
          ],
        };
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    getSubjectName(id) {
      if (!Number(id)) {
        return "总分";
      } else {
        const data = this.subjectList.find((item) => item.id == id);
        return data ? data.name : "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.focusOnStudent {
  .radio {
    display: flex;
    justify-content: flex-end;
  }
  .tableList {
    margin-bottom: 24px;

    ::v-deep .hover-row {
      .el-table__cell {
        background-color: #fff5d7;
      }
    }
  }
  .filter {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    .filter-item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      span {
        margin: 0 8px;
      }
      .label {
        color: #080a09;
        font-size: 14px;
        margin-right: 8px;
      }
    }
  }
  .tag {
    font-size: 14px;
    border: 1px dashed #2474ed;
    color: #737677;
    background-color: #f2f8fe;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 24px;
    line-height: 1.5;
    div {
      margin-bottom: 12px;
    }
    & :last-child {
      margin: 0;
    }
  }
}
.nameList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  div {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 10px;
  }
}
.on {
  color: #2474ed;
}
.onBack {
  color: #6edba7;
}
.offBack {
  color: #fd8585;
}
.off {
  color: #ff9776;
}
.subjectShit {
  color: #f56c6c;
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;
  &:hover {
    color: #fff;
  }
}
::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
