<template>
  <div ref="brach" v-loading="loading" class="branch card">
    <div class="section-sub-title">成绩分布</div>
    <div class="chart">
      <div class="operation" style="margin-bottom: 24px">
        <div class="filter">
          <div class="label">分数段区间设置：</div>
          <div class="filter-item">
            <div class="filter-item-label">上限</div>
            <el-input
              v-model.number="search.high"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 72px"
              @blur="search.high = $event.target.value"
            ></el-input>
            <div class="unit">分</div>
          </div>
          <div class="filter-item">
            <div class="filter-item-label">下限</div>
            <el-input
              v-model.number="search.low"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 72px"
              @blur="search.low = $event.target.value"
            ></el-input>
            <div class="unit">分</div>
          </div>
          <div class="filter-item">
            <div class="filter-item-label">间隔</div>
            <el-input
              v-model.number="search.interval"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 72px"
              @blur="search.interval = $event.target.value"
            ></el-input>
            <div class="unit">分</div>
          </div>
          <el-button type="primary" plain @click="getScoredistribution"
            >确定</el-button
          >
        </div>
        <el-radio-group
          v-model="search.type"
          size="small"
          @change="raidoChange"
        >
          <el-radio-button :label="1">人数</el-radio-button>
          <el-radio-button :label="2">占比</el-radio-button>
        </el-radio-group>
      </div>
      <barChart v-if="chartOptions" :key="chartKey" :data="chartOptions" />
      <no-data v-else></no-data>
      <div class="model-desc mt30">注：红色柱子表示我校平均分所在分数段</div>
    </div>
  </div>
</template>

<script>
import barChart from "./chart.vue";
import { scoredistribution } from "@/core/api/academic/schoolReportDetail";
import { unionScoredistribution } from "@/core/api/academic/unionReport";
export default {
  name: "Branch",
  components: {
    barChart,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: {
        type: 1,
        high: 100,
        low: 10,
        interval: 10,
      },
      allData: [],
      statId: this.$route.query.id,
      chartOptions: null,
      loading: false,
      chartKey: Math.random(),
    };
  },
  mounted() {
    let { segmentMaximum, segmentMinimum, segmentGap } = this.topData;
    this.search.high = segmentMaximum;
    this.search.low = segmentMinimum;
    this.search.interval = segmentGap;
    if (segmentMaximum && segmentMinimum && segmentGap) {
      this.getScoredistribution();
    }
  },

  methods: {
    raidoChange() {
      if (this.allData.length) {
        let series = [];
        if (this.$route.query.examType != 1) {
          series = [
            {
              name: this.search.type === 1 ? "我校人数" : "我校占比", // 不同条柱
              type: "bar",
              label: {
                show: true,
                position: "top",
                color: "inherit",
                fontSize: 9,
              },
              data:
                this.search.type === 1
                  ? this.allData.map((item) => {
                      if (item.isGradeAverage) {
                        return {
                          value: item.total,
                          itemStyle: { color: "#FF6833" },
                        };
                      } else {
                        return item.total;
                      }
                    })
                  : this.allData.map((item) => {
                      if (item.isGradeAverage) {
                        return {
                          value: item.proportion,
                          itemStyle: { color: "#FF6833" },
                        };
                      } else {
                        return item.proportion;
                      }
                    }),
              barMaxWidth: 40,
            },
          ];
        } else {
          series = [
            {
              name: this.search.type === 1 ? "我校人数" : "我校占比", // 不同条柱
              type: "bar",
              label: {
                show: true,
                position: "top",
                color: "inherit",
                fontSize: 9,
              },
              data:
                this.search.type === 1
                  ? this.allData.map((item) => {
                      if (item.isGradeAverage) {
                        return {
                          value: item.total,
                          itemStyle: { color: "#FF6833" },
                        };
                      } else {
                        return item.total;
                      }
                    })
                  : this.allData.map((item) => {
                      if (item.isGradeAverage) {
                        return {
                          value: item.proportion,
                          itemStyle: { color: "#FF6833" },
                        };
                      } else {
                        return item.proportion;
                      }
                    }),
              barMaxWidth: 40,
            },
          ];
          if (this.search.type == 2) {
            series.push({
              type: "line",
              name: "整体",
              smooth: true,
              color: "#FF8857",
              data: this.allData.map((item) => item.unionProportion),
            });
          }
        }
        this.chartOptions = {
          grid: {
            bottom: this.allData.length > 25 ? "20%" : "5%",
            containLabel: true,
          },
          dataZoom: [
            //滚动条
            {
              show: this.allData.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: this.allData.length - (this.allData.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          xAxis: [
            {
              type: "category",
              data: this.allData.map((item) => item.segment),
              axisTick: {
                show: false,
              },
              axisLabel: {
                rotate: 25,
              },
            },
          ],
          series: [...series],
        };
        this.chartKey = Math.random();
      }
    },
    async getScoredistribution() {
      let res;
      try {
        this.loading = true;
        if (this.$route.query.examType == 1) {
          res = await unionScoredistribution({
            id: this.statId,
            ...this.search,
            viewSchoolId: this.$route.query.schoolId,
          });
        } else {
          res = await scoredistribution({
            id: this.statId,
            ...this.search,
          });
        }
        this.allData = res.data.data;
        this.loading = false;
      } catch {
        this.loading = false;
      }

      this.raidoChange();
    },
  },
};
</script>
<style lang="scss" scoped>
.branch {
  margin-bottom: 24px;
  .title {
    font-size: 16px;
    margin-bottom: 30px;
    font-weight: bold;
    color: #333;
    span {
      color: #7a7a7a;
      font-size: 14px;
      font-weight: normal;
      margin-left: 24px;
    }
  }
  .chart {
    padding: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    .operation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .filter {
        display: flex;
        align-items: center;
        color: #5e6166;
        font-size: 14px;
        flex-wrap: wrap;
        .label {
          color: #080a09;
          font-size: 14px;

          margin-right: 24px;
        }
        .filter-item {
          display: flex;
          align-items: center;
          margin-right: 24px;
          font-size: 14px;
          color: #5e6166;
          .el-input {
            margin: 0 8px;
          }
          @media screen and (max-width: 1400px) {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
.el-table {
  &::v-deep {
    .el-table__body-wrapper::-webkit-scrollbar {
      /*width: 0;宽度为0隐藏*/
      height: 10px;
    }
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 8px;
      height: 8px;
      background: #a1a1a1; //滚动条颜色
    }
    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(241, 240, 245, 0.2);
      background: #ececec; //滚动条背景色
      height: 8px;
    }
  }
}
::v-deep .el-table__fixed {
  height: calc(100% - 10px) !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;
  &:hover {
    color: #fff;
  }
}
::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
