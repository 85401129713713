<template>
  <div class="schoolReport">
    <div class="topCard">
      <tag>{{ schoolName }}</tag>
      <div class="title-box">
        <div class="title">
          <div v-if="$route.query.ruleId > 0" class="new-exam-tag">新高考</div>
          {{ topData.name }}
          <div :class="['sub-tag', topData.type == 99 ? 'other' : 'general']">
            {{ getExamType(topData.type) }}
          </div>
        </div>
        <!-- <el-button type="primary" plain size="mini" @click="exportPDF"
          >导出PDF</el-button
        > -->
      </div>
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <export-btn
        btn-type="primary"
        text="导出全部报表"
        :request-func="exportSchoolReport"
        :params="{
          id: $route.query.id,
          type: 0,
        }"
      >
        <div class="sub-title">
          <div>
            {{ topData.examDate }}&nbsp;&nbsp;&nbsp;&nbsp;
            <span v-if="level == 2">初</span><span v-if="level == 3">高</span
            >{{ topData.year }}级
          </div>
        </div>
      </export-btn>

      <div class="model-title">校级报告</div>
      <div v-if="topData.year" class="dashboard">
        <div class="examinfo-box">
          <div class="fl">
            <p>
              <i>{{ topData.classCount }}</i
              >个班级
            </p>
            <p>
              <i>{{ topData.studentCount }}</i
              >名学生
            </p>
          </div>
          <span class="info-line"></span>
          <div class="refer-score fl">
            <p>{{ topData.highestScore }}最高分</p>
            <p>{{ topData.lowestScore }}最低分</p>
          </div>
        </div>
        <div class="report-scorebox bg-lightblue">
          <p class="ft-blue">{{ topData.averageScore }}</p>
          <span>平均分</span>
        </div>
        <ul
          v-if="topData.topInfoOnlineVOs.length"
          v-isUnionShow="'statClass'"
          class="report-rankingbox bg-lightorange ml30"
        >
          <li v-for="(item, index) in topData.topInfoOnlineVOs" :key="index">
            <p class="ft-orange">{{ item.value }}%</p>
            <span>{{ item.name }}上线率</span>
          </li>
        </ul>
        <ul class="report-rankingbox bg-lightgreen ml30">
          <li v-for="(item, index) in topData.topInfoRatioVOs" :key="index">
            <p class="ft-green">{{ item.value }}%</p>
            <span class="label">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
    <template v-if="topData.name">
      <attendance id="attendance" :top-data="topData" />
      <branch-component :top-data="topData" />
      <class-competitive
        id="classCompetitive"
        v-isUnionShow="'statClass'"
        :top-data="topData"
      />
      <class-and-subject
        id="classAndSubject"
        v-isUnionShow="'statClass'"
        :top-data="topData"
      />
      <contribution
        v-if="
          (examType == 1 && topData.topInfoOnlineVOs.length) || examType != 1
        "
        id="contribution"
        v-isUnionShow="'statClass'"
        :top-data="topData"
      />
      <focus-on-student id="focusOnStudent" :top-data="topData" />
      <develop-analysis id="developAnalysis" :top-data="topData" />
      <page-nav :menu-list="menuList" :top-data="topData" />
    </template>
  </div>
</template>

<script>
import tag from "../components/tag.vue";
import attendance from "./components/attendance.vue";
import branchComponent from "./components/branch.vue";
import classCompetitive from "./components/classCompetitive.vue";
import classAndSubject from "./components/classAndSubject.vue";
import contribution from "./components/contribution";
import focusOnStudent from "./components/focusOnStudenet.vue";
import developAnalysis from "./components/developAnalysis.vue";
import pageNav from "../components/pageNav.vue";
import {
  getschoolreporttopinfo,
  downloadschoolpdfreport,
  exportSchoolReport,
} from "@/core/api/academic/schoolReportDetail";
import {
  getschoolUnionReporttopinfo,
  downloadUnionschoolpdfreport,
} from "@/core/api/academic/unionReport";
import { examTypeOptions } from "@/core/util/constdata";
import { fileDownloadByUrl } from "@/core/util/util";
import exportBtn from "@/views/academic/components/exportBtn";
export default {
  name: "SchoolReport",
  components: {
    tag,
    exportBtn,
    attendance,
    branchComponent,
    classCompetitive,
    classAndSubject,
    contribution,
    focusOnStudent,
    developAnalysis,
    pageNav,
  },
  data() {
    return {
      schoolName: "",
      level: null,
      exportSchoolReport: exportSchoolReport,
      menuList: [
        {
          name: "考情概述",
          id: "attendance",
        },
        {
          name: "班级竞争力",
          id: "classCompetitive",
        },
        {
          name: "班级&学科",
          id: "classAndSubject",
        },
        {
          name: "学科贡献分析",
          id: "contribution",
        },

        {
          name: "重点关注学生",
          id: "focusOnStudent",
        },
        {
          name: "发展性分析",
          id: "developAnalysis",
        },
      ],
      statId: null,
      examType: this.$route.query.examType,
      viewSchoolId: null,
      topData: {},
    };
  },
  mounted() {
    const { id, level, examType, schoolId } = this.$route.query;
    this.statId = id;
    this.viewSchoolId = schoolId;
    this.examType = examType;
    this.level = level;
    this.getTopData(id);
  },
  methods: {
    async exportPDF() {
      if (this.examType == 1) {
        const res = await downloadUnionschoolpdfreport({
          viewSchoolId: this.viewSchoolId,
          id: this.statId,
        });
        if (res.data.data.url) {
          fileDownloadByUrl(res.data.data);
        }
      } else {
        const res = await downloadschoolpdfreport({
          id: this.statId,
        });
        if (res.data.data.url) {
          fileDownloadByUrl(res.data.data);
        }
      }
    },
    back() {
      this.$router.go("-1");
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
    async getTopData(id) {
      if (this.examType == 1) {
        const res = await getschoolUnionReporttopinfo({
          id: id,
          viewSchoolId: this.viewSchoolId,
        });
        this.topData = res.data.data;
        if (this.topData.statClass != 1) {
          this.menuList.splice(1, 2);
        }

        if (this.examType == 1 && !this.topData.topInfoOnlineVOs.length) {
          this.menuList = this.menuList.filter(
            (item) => item.name != "学科贡献分析"
          );
        }

        this.$store.commit("report/SET_TOPDATA", res.data.data);
      } else {
        const res = await getschoolreporttopinfo({ id: id });
        this.topData = res.data.data;
        this.$store.commit("report/SET_TOPDATA", res.data.data);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.schoolReport {
  width: 1200px;
  margin: 0 auto;
}
ul {
  margin-bottom: 0 !important;
}
.sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sub-title {
    justify-content: center;
  }
  .export-btn {
    text-align: right;
  }
  div {
    flex: 1;
  }
}
</style>
