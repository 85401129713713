<template>
  <div ref="Attendance" v-lazyChart="{ fn: getData }" class="card container">
    <export-btn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 1,
      }"
    >
      <div class="section-title">考情概述</div>
    </export-btn>
    <div class="model-desc mt30">
      <div class="indent">{{ overview }}</div>
      <div class="indent">注：本报告中的前N名均为我校范围内的前N名</div>
    </div>
    <div class="section-sub-title">校学科竞争力</div>
    <div class="tableList">
      <el-table
        ref="elTable"
        class="table-fixed"
        :data="tableData"
        :span-method="objectSpanMethod"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          label="学科"
          fixed
          width="80"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ getSubjectName(row.subjectId) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="examType == 1"
          prop="schoolName"
          label="学校"
          fixed
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="statTotal"
          label="统计人数"
          fixed
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="averageScore"
          fixed
          label="平均分"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in onlines"
          :key="index"
          :label="item.name"
          align="center"
        >
          <el-table-column prop="ep" label="有效分" width="100" align="center">
            <template slot-scope="{ row }">{{
              row.onlines[index].ep
            }}</template>
          </el-table-column>
          <el-table-column prop="total" label="人数" width="100" align="center">
            <template slot-scope="{ row }">{{
              row.onlines[index].total
            }}</template>
          </el-table-column>
          <el-table-column
            prop="proportion"
            label="占比（%）"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.onlines[index].proportion || 0
            }}</template>
          </el-table-column>
          <el-table-column
            v-if="topData.isSchoolDisplayReportSchoolRank == 1"
            prop="rank"
            label="排名"
            width="85"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.onlines[index].rank || "-"
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in ratios"
          :key="'ratios' + index"
          :label="item.name"
          align="center"
        >
          <el-table-column label="人数" prop="total" width="100" align="center">
            <template slot-scope="{ row }">{{
              row.ratios[index].total
            }}</template>
          </el-table-column>
          <el-table-column
            prop="proportion"
            label="占比（%）"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.ratios[index].proportion || 0
            }}</template>
          </el-table-column>
          <el-table-column
            v-if="topData.isSchoolDisplayReportSchoolRank == 1"
            prop="rank"
            label="排名"
            width="85"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.ratios[index].rank || "-"
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in topSections"
          :key="'topSections' + index"
          :label="item.name"
          align="center"
        >
          <el-table-column label="人数" prop="total" width="100" align="center">
            <template slot-scope="{ row }">{{
              row.topSections[index].total
            }}</template>
          </el-table-column>
          <el-table-column
            prop="proportion"
            label="占比（%）"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.topSections[index].proportion || 0
            }}</template>
          </el-table-column>
          <el-table-column
            v-if="topData.isSchoolDisplayReportSchoolRank == 1"
            prop="rank"
            label="排名"
            width="85"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.topSections[index].rank || "-"
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="highestScore"
          label="最高分"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="lowestScore"
          label="最低分"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="rangeScore"
          label="全距"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="median"
          label="中位数"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="standardDeviation"
          label="标准差"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="coefficientOfVariation"
          width="150"
          label="差异系数"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="chart-box">
      <div class="operation">
        <div class="selects">
          <div v-if="category === 5" class="selects-item">
            选择指标：
            <el-select
              v-model="otherType"
              style="width: 174px"
              placeholder="请选择"
              @change="otherSelectChange"
            >
              <el-option
                v-for="item in otherOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-radio-group v-model="category" size="small" @change="radioChange">
          <el-radio-button :label="1">平均分</el-radio-button>
          <el-radio-button
            v-if="
              (examType == 1 && topData.topInfoOnlineVOs.length) ||
              examType != 1
            "
            :label="2"
            >上线情况</el-radio-button
          >
          <el-radio-button :label="3">比率</el-radio-button>
          <el-radio-button :label="4">前N名</el-radio-button>
          <el-radio-button :label="5">其他指标</el-radio-button>
        </el-radio-group>
      </div>
      <div v-if="examType == 1" class="select">
        <el-select
          v-if="[2, 3, 4].includes(category)"
          v-model="type"
          placeholder="请选择"
          @change="typeChange"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="chart">
        <bar-chart :data="chartOptions" :height="500" />
      </div>
    </div>
  </div>
</template>

<script>
import barChart from "./chart.vue";
import {
  subjectcompetitive,
  getstatInfo,
  exportSchoolReport,
} from "@/core/api/academic/schoolReportDetail";
import { unionsubjectcompetitive } from "@/core/api/academic/unionReport";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import axios from "axios";
export default {
  name: "Attendance",
  components: {
    barChart,
    exportBtn,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      requestFunc: exportSchoolReport,
      type: "",
      overview: "",
      tableData: [],
      chartOptions: {},
      examType: this.$route.query.examType,
      search: {
        category: 1,
      },
      options: [
        {
          label: "最高分",
        },
        {
          label: "最低分",
        },
        {
          label: "全距",
        },
        {
          label: "中位数",
        },
        {
          label: "离均差",
        },
        {
          label: "超均差",
        },
        {
          label: "标准差",
        },
        {
          label: "异差系数",
        },
      ],
      category: 1,
      statId: this.$route.query.id,
      onlines: [],
      topSections: [],
      ratios: [],
      categoryField: [],
      chartData: [],
      otherOptions: [],
      otherType: "median",
    };
  },
  mounted() {
    this.$refs.elTable.doLayout();
  },
  updated() {
    this.$refs.elTable.doLayout();
  },
  methods: {
    async getData(loadingInstance, io) {
      const res = await axios.all([
        this.getSubjectcompetitive(),
        this.getstatInfoData(),
      ]);
      const [competitive, subject] = res;
      if (this.$route.query.examType == 1) {
        this.overview = competitive.data.data.overview;
        this.chartData = competitive.data.data.subjectVOs;
        this.tableData = competitive.data.data.subjectVOs.flatMap((item) => {
          let schools =
            this.topData.isSchoolDisplayReportUnionAverageScore == 1
              ? item.schools
              : item.schools.filter((item) => item.schoolName !== "整体");
          return schools.map((it) => ({ ...item, ...it }));
        });

        if (competitive.data.data.subjectVOs.length) {
          if (competitive.data.data.subjectVOs[0].schools.length) {
            this.onlines =
              competitive.data.data.subjectVOs[0].schools[0].onlines;
            this.topSections =
              competitive.data.data.subjectVOs[0].schools[0].topSections;
            this.ratios = competitive.data.data.subjectVOs[0].schools[0].ratios;
          }
        }
      } else {
        this.overview = competitive.data.data.overview;
        this.chartData = competitive.data.data.subjectVOs;
        this.tableData = competitive.data.data.subjectVOs;
        if (competitive.data.data.subjectVOs) {
          this.onlines = competitive.data.data.subjectVOs[0].onlines;
          this.topSections = competitive.data.data.subjectVOs[0].topSections;
          this.ratios = competitive.data.data.subjectVOs[0].ratios;
        }
      }

      this.subjectList = [
        { name: "总分", subjectId: "0" },
        ...subject.data.data.statSubjects,
      ];

      this.averageScoreFilter();
      this.getstatInfoData();
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
        loadingInstance.close();
        io.unobserve(this.$refs.Attendance);
      });
    },
    async getSubjectcompetitive() {
      if (this.$route.query.examType == 1) {
        return unionsubjectcompetitive({
          id: this.statId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        return subjectcompetitive({ id: this.statId });
      }
    },
    getstatInfoData() {
      // 获取统计科目
      return getstatInfo({ id: this.statId });
    },
    otherSelectChange() {
      if (this.$route.query.examType == 1) {
        this.setChartUnion();
      } else {
        let item = this.otherOptions.find(
          (item) => item.value === this.otherType
        );
        let arr = this.tableData.filter((item) => item.subjectId != 0);
        let subjects = this.tableData.map((item) => {
          if (item.subjectId != 0) {
            const data = this.subjectList.find(
              (it) => it.subjectId === item.subjectId
            );
            return {
              name: data.name,
              subjectId: item.subjectId,
            };
          }
        });
        subjects = subjects.filter((item) => item);
        if (this.otherType == "coefficientOfVariation") {
          arr = this.tableData;
          subjects = this.subjectList;
        }
        this.chartOptions = {
          grid: {
            bottom: subjects.length > 25 ? "20%" : "5%",
          },
          dataZoom: [
            //滚动条
            {
              show: subjects.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: subjects.length - (subjects.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          xAxis: [
            {
              type: "category",
              data: subjects.map((item) => item.name),
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#999",
                  fontSize: 12,
                },
              },
            },
          ],
          series: [
            {
              name: item.label, // 不同条柱
              type: "bar",
              label: {
                show: true,
                textStyle: {
                  color: "#7AB5EF",
                },
                color: "inherit",
                fontSize: 9,
                position: "top",
              },
              data: arr.map((item) => item[this.otherType]),
              barMaxWidth: 40,
            },
          ],
        };
      }
    },
    selectChange() {},
    typeChange() {
      switch (this.category) {
        case 1:
          this.averageScoreFilter();
          break;
        case 2:
          this.onlinesFilter();
          break;
        case 3:
          this.ratiosFilter();
          break;
        case 4:
          this.topSectionsFilter();
          break;
        case 5:
          this.otherSelectChange();
          break;
      }
    },
    radioChange() {
      switch (this.category) {
        case 1:
          this.averageScoreFilter();
          break;
        case 2:
          // this.options = this.on
          this.options = this.onlines.map((item) => ({
            label: item.name,
            value: item.name,
          }));
          if (this.onlines.length) {
            this.type = this.options[0].value;
          }
          this.onlinesFilter();
          break;
        case 3:
          this.options = this.ratios.map((item) => ({
            label: item.name,
            value: item.name,
          }));
          if (this.ratios.length) {
            this.type = this.options[0].value;
          }
          this.ratiosFilter();
          break;
        case 4:
          this.options = this.topSections.map((item) => ({
            label: item.name,
            value: item.name,
          }));
          if (this.topSections.length) {
            this.type = this.options[0].value;
          }
          this.topSectionsFilter();
          break;
        case 5:
          this.otherType = "median";
          this.otherOptions = [
            {
              label: "最高分",
              value: "highestScore",
            },
            {
              label: "最低分",
              value: "lowestScore",
            },
            {
              label: "全距",
              value: "rangeScore",
            },
            {
              label: "中位数",
              value: "median",
            },

            {
              label: "标准差",
              value: "standardDeviation",
            },
            {
              label: "差异系数",
              value: "coefficientOfVariation",
            },
          ];
          if (this.examType == 1) {
            this.otherOptions.splice(
              3,
              0,
              {
                label: "超均率（整体）",
                value: "overAverageRate",
              },
              {
                label: "离均差（整体）",
                value: "deviationFromMean",
              }
            );
          }
          this.otherSelectChange();
          break;
      }
    },

    averageScoreFilter() {
      const data = this.chartData.filter((item) => item.subjectId != 0);

      let subjects = this.chartData.map((item) => {
        if (item.subjectId != 0) {
          const data = this.subjectList.find(
            (it) => it.subjectId === item.subjectId
          );
          return {
            name: data.name,
            subjectId: item.subjectId,
          };
        }
      });
      subjects = subjects.filter((item) => item);
      const xAxis = subjects.map((item) => item.name);
      let series = [];
      if (this.examType == 1) {
        series = [
          {
            name: "本校", // 不同条柱
            type: "bar",
            label: {
              show: true,
              textStyle: {
                color: "inherit",
              },
              position: "top",
              color: "inherit",
              fontSize: 9,
            },
            data: data.map(
              (item) =>
                item.schools.find((it) => it.schoolName == "本校").averageScore
            ),
            barMaxWidth: 40,
          },
        ];
        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          series.push({
            name: "整体", // 不同条柱
            type: "line",
            smooth: true,
            color: "#FF8857",
            data: data.map(
              (item) =>
                item.schools.find((it) => it.schoolName == "整体").averageScore
            ),
          });
        }
      } else {
        series = [
          {
            name: "平均分", // 不同条柱
            type: "bar",
            label: {
              show: true,
              textStyle: {
                color: "#7AB5EF",
              },
              position: "top",
              color: "inherit",
              fontSize: 9,
            },
            data: data.map((item) => item.averageScore),
            barMaxWidth: 40,
          },
        ];
      }
      this.chartOptions = {
        grid: {
          bottom: this.subjectList.length > 25 ? "20%" : "5%",
        },
        legend: {},
        dataZoom: [
          //滚动条
          {
            show: this.subjectList.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: this.subjectList.length - (this.subjectList.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisTick: {
              show: false,
            },
          },
        ],
        series: [...series],
      };
    },
    onlinesFilter() {
      if (this.$route.query.examType == 1) {
        this.setChartUnion();
      } else {
        let noTotalArr = this.tableData.filter((item) => item.subjectId != 0);
        const arr = noTotalArr.flatMap((item) => item.onlines);
        const names = [...new Set(arr.map((item) => item.name))];
        let subjects = this.tableData.map((item) => {
          if (item.subjectId != 0) {
            const data = this.subjectList.find(
              (it) => it.subjectId == item.subjectId
            );
            return {
              name: data.name,
              subjectId: item.subjectId,
            };
          }
        });
        subjects = subjects.filter((item) => item);
        const xAxis = subjects.map((item) => item.name);
        this.chartOptions = {
          grid: {
            bottom: this.subjectList.length > 25 ? "20%" : "5%",
          },
          dataZoom: [
            //滚动条
            {
              show: this.subjectList.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue:
                this.subjectList.length - (this.subjectList.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          xAxis: [
            {
              type: "category",
              data: xAxis,
              axisTick: {
                show: false,
              },
            },
          ],
          series: names.map((item) => {
            return {
              name: item, // 不同条柱
              type: "bar",
              label: {
                show: true,
                position: "top",
                color: "inherit",
                fontSize: 9,
              },
              barMaxWidth: 40,

              data: [
                ...arr
                  .filter((it) => item === it.name)
                  .map((item) => item.proportion),
              ],
            };
          }),
        };
      }
    },
    objectSpanMethod({ row, rowIndex, columnIndex }) {
      let targetIndex = this.tableData.findIndex(
        (item) => item.subjectId == row.subjectId
      );
      let arr = this.tableData.filter(
        (item) => item.subjectId == row.subjectId
      );
      if (columnIndex === 0) {
        if (rowIndex == targetIndex) {
          return {
            rowspan: arr.length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1,
        };
      }
    },
    ratiosFilter() {
      if (this.$route.query.examType == 1) {
        this.setChartUnion();
      } else {
        const arr = this.tableData.flatMap((item) => item.chartRatios);
        const names = [...new Set(arr.map((item) => item.name))];
        let subjects = this.tableData.map((item) => {
          const data = this.subjectList.find(
            (it) => it.subjectId == item.subjectId
          );
          if (data) {
            return {
              name: data.name,
              subjectId: item.subjectId,
            };
          }
        });
        subjects = subjects.filter((item) => item);
        const xAxis = subjects.map((item) => item.name);
        this.chartOptions = {
          grid: {
            bottom: this.subjectList.length > 25 ? "20%" : "5%",
          },
          legend: {
            itemGap: 30,
          },
          dataZoom: [
            //滚动条
            {
              show: this.subjectList.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue:
                this.subjectList.length - (this.subjectList.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          xAxis: [
            {
              type: "category",
              data: xAxis,
              axisTick: {
                show: false,
              },
            },
          ],

          series: names.map((item) => {
            return {
              name: item, // 不同条柱
              type: "bar",
              stack: "Ad",
              label: {
                show: false,
                position: "inside",
                color: "#3E4551",

                formatter: function (params) {
                  const { value } = params;
                  if (value > 0) {
                    return value;
                  } else {
                    return "";
                  }
                },
              },
              barMaxWidth: 40,

              data: [
                ...arr
                  .filter((it) => item === it.name)
                  .map((item) => item.proportion),
              ],
            };
          }),
        };
      }
    },
    topSectionsFilter() {
      if (this.$route.query.examType == 1) {
        this.setChartUnion();
      } else {
        const arr = this.tableData.flatMap((item) => item.topSections);
        const names = [...new Set(arr.map((item) => item.name))];
        let subjects = this.tableData.map((item) => {
          const data = this.subjectList.find(
            (it) => it.subjectId == item.subjectId
          );
          return {
            name: data.name,
            subjectId: item.subjectId,
          };
        });
        subjects = subjects.filter((item) => item);
        const xAxis = subjects.map((item) => item.name);
        this.chartOptions = {
          legend: {
            itemGap: 30,
          },
          xAxis: [
            {
              type: "category",
              data: xAxis,
              axisTick: {
                show: false,
              },
            },
          ],
          grid: {
            left: "0%",
            right: "0%",
            bottom: arr.length > 25 ? "20%" : "5%",
            containLabel: true,
          },
          dataZoom: [
            //滚动条
            {
              show: arr.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: arr.length - (arr.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          series: names.map((item) => {
            return {
              name: item, // 不同条柱
              type: "bar",
              label: {
                show: true,
                position: "top",
                color: "inherit",
                fontSize: 9,
              },
              barMaxWidth: 40,

              data: [
                ...arr
                  .filter((it) => item === it.name)
                  .map((item) => item.proportion),
              ],
            };
          }),
        };
      }
    },

    getSubjectName(id) {
      if (!Number(id)) {
        return "总分";
      } else {
        const data = this.subjectList.find((item) => item.subjectId === id);
        return data.name;
      }
    },
    setChartUnion() {
      let enmu = {
        2: "onlines",
        3: "ratios",
        4: "topSections",
      };
      let key;
      let series = [];
      if ([2, 3, 4].includes(this.category)) {
        key = enmu[this.category];
        series = [
          {
            name: "本校", // 不同条柱
            type: "bar",
            label: {
              show: true,
              textStyle: {
                color: "inherit",
              },
              position: "top",
              color: "inherit",
              fontSize: 9,
            },
            data: this.chartData.map((item) => {
              let school = item.schools.find((it) => it.schoolName == "本校");
              let col = school[key].find((it) => it.name === this.type);
              return {
                value: col.proportion,
                label: {
                  position: col.proportion >= 0 ? "top" : "bottom",
                  show: true,
                  color: "inherit",
                  fontSize: 9,
                },
              };
            }),
            barMaxWidth: 40,
          },
        ];
        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          series.push({
            name: "整体", // 不同条柱
            type: "line",
            smooth: true,
            color: "#FF8857",
            data: this.chartData.map((item) => {
              let school = item.schools.find((it) => it.schoolName == "整体");
              let col = school[key].find((it) => it.name === this.type);
              return {
                value: col.proportion,
                label: {
                  position: col.proportion >= 0 ? "top" : "bottom",
                  show: true,
                  color: "inherit",
                  fontSize: 9,
                },
              };
            }),
          });
        }
      } else if (this.category == 5) {
        key = this.otherType;
        series = [
          {
            name: "本校", // 不同条柱
            type: "bar",
            label: {
              show: true,
              textStyle: {
                color: "inherit",
              },
              position: "top",
              color: "inherit",
              fontSize: 9,
            },
            data: this.chartData.map((item) => {
              let school = item.schools.find((it) => it.schoolName == "本校");
              return {
                value: school[key],
                label: {
                  position: school[key] >= 0 ? "top" : "bottom",
                  show: true,
                  color: "inherit",
                  fontSize: 9,
                },
              };
            }),
            barMaxWidth: 40,
          },
        ];
        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          series.push({
            name: "整体", // 不同条柱
            type: "line",
            smooth: true,
            color: "#FF8857",
            data: this.chartData.map((item) => {
              let school = item.schools.find((it) => it.schoolName == "整体");
              return {
                value: school[key],
                label: {
                  position: school[key] >= 0 ? "top" : "bottom",
                  show: true,
                  color: "inherit",
                  fontSize: 9,
                },
              };
            }),
          });
        }
      }
      const xAxis = this.chartData.map((item) => item.subjectName);

      this.chartOptions = {
        grid: {
          bottom: xAxis.length > 25 ? "20%" : "5%",
        },
        legend: {},
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisTick: {
              show: false,
            },
          },
        ],
        series: [...series],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 30px;
  .tag {
    font-size: 14px;
    border: 1px dashed #2474ed;
    color: #737677;
    background-color: #f2f8fe;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 24px;
    div {
      margin-bottom: 12px;
      line-height: 1.5;
    }
    & :last-child {
      margin: 0;
    }
  }

  .tableList {
    margin-bottom: 32px;
    // #FFF5D7
    ::v-deep .hover-row {
      .el-table__cell {
        background-color: #fff5d7;
      }
    }
  }
  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .chart {
    padding: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #2474ed;
  border-color: #2474ed;
}

.select {
  margin-bottom: 18px;
}
.operate {
  display: flex;
  justify-content: space-between; /* 两端对齐 */
  position: relative;
}
.centered {
  text-align: center; /* 文字居中 */
  flex: 1; /* 占据剩余空间 */
}
.right-aligned {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>
